import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomTablePagination from '../../../../../../components/PanelCustomTablePagination';

interface Column {
  id: 'nome' | 'mulheresComHpv16_18' | 'totalColposcopias' | 'colposcopiasARealizar';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
  width?: number;
}

interface TableHPVColpoProps {
  data: any[];
  totals: {
    totalMulheresComHpv16_18: number;
    totalColposcopias: number;
    totalColposcopiasARealizar: number;
  };
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  localizacaoType: string;
}

export default function TableHPVColpo({
  data,
  totals,
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowsPerPageChange,
  localizacaoType,
}: TableHPVColpoProps) {

  const columns: readonly Column[] = [
    { id: 'nome', label: localizacaoType || 'Localidade', minWidth: 170 }, 
    { id: 'mulheresComHpv16_18', label: 'Mulheres com HPV 16 ou 18', minWidth: 170, width: 292 },
    { id: 'totalColposcopias', label: 'Colposcopias Realizadas', minWidth: 170 },
    { id: 'colposcopiasARealizar', label: 'Colposcopias a Realizar', minWidth: 170 },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };
  return (
    <Box className="table-container">
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} className="table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>Sem Resultados</TableCell>
                </TableRow>
              ) : (
                <>
                  {data?.map((row: any, index: number) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell>{row.mulheresComHpv16_18}</TableCell>
                      <TableCell>{row.totalColposcopias}</TableCell>
                      <TableCell
                        style={{
                          color: row.colposcopiasARealizar < 0 ? 'red' : 'inherit',
                        }}
                      >
                        {row.colposcopiasARealizar}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Total
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals?.totalMulheresComHpv16_18}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals?.totalColposcopias}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals?.totalColposcopiasARealizar}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          page={currentPage - 1}
          rowsPerPage={itemsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`}
        />
      </Paper>
    </Box>
  );
}