import { Box, Grid, Typography } from '@mui/material';
import { AreaChart } from '../components/areaChart';
import styles from './styles.module.css';
import { ChartCard } from '../components/chartCard';

import { useForm } from 'react-hook-form';
import FiltersVisaoGeral from '../components/filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import { useContext, useEffect, useState } from 'react';
import api from '../../../../api';
import { getUserToken } from '../../../../lib/auth';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import TipoAchadosStackedBarsChart from '../components/TipoAchadosStackedBarsChart/TipoAchadosStackedBarsChart';

import { LoadingContext } from '../../../../context/LoadingContext';
//C:\Users\gabri\OneDrive\Documentos\GitHub\Jordana-CCU-Webapp\src\pages\Panels\colposcopia\components\avaliacaoGeralChart\index.tsx
import { AvaliacaoGeral } from '../components/avaliacaoGeralChart';
import { TableCard } from '../../../../components/TableExamesHistopatologia/TableCard';
import TableHPVColpo from '../components/TableExamesHistopatologia/tableHpvColpo/TableExamesColposcopia';
import TableCitoColpo from '../components/TableExamesHistopatologia/tableCitoColpo/TableExamesColposcopia';

import { get } from 'http';
import CardRealizarBiopsia from '../components/CardRealizarBiopsia';
import EmptyData from '../../../../components/Charts/emptyData';
const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'periodo_inicio', value: '' },
  { inputName: 'periodo_fim', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const { control } = useForm();
  const [valueInputPeriod, setValueInputPeriod] = useState('Todos');

  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');

  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [countLoading, setCountLoading] = useState(0);
  const [filtersData, setFiltersData] = useState<any>();
  const userToken = getUserToken();
  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;

  const [payload, setPayload] = useState<any>();
  const [total, setTotal] = useState<any>();
  useState<any>([]);

  const [avaliacaoGeralChartData, setavaliacaoGeralChartData] = useState<any>(
    []
  );

  const [avaliacaoGeralLabels, setavaliacaoGeralLabels] = useState<any>([]);
  const emptyAvaliacaoGeral = !avaliacaoGeralLabels || avaliacaoGeralLabels.length === 0;



  const [realizarBiopsiaData, setRealizarBiopsiaData] = useState({
    totalSim: 0,
    totalNao: 0,
    simPercent: 0,
    naoPercent: 0,
  });

  const { setLoading } = useContext(LoadingContext);

  const getTestesPerMonth = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/quantity-month',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,

              },
        }
      );

      const data = response.data.data.data;
      const total = response.data.data.total;

      if (!Array.isArray(data) || data.length === 0) {
        setCategories(['NÃO HÁ DADOS']);
        setTotalTestsPerMonth([]);
        setTotal(null);
        setFiltersData(response.data.filters);
        setCountLoading((prev) => prev + 1);
      } else {
        setFiltersData(response.data.filters);
        setCountLoading((prev) => prev + 1);
        setCategories(
          data.map((item, index) => {
            if (index === 0 || item.month === 'Jan') {
              return [
                `${String(item.month).toUpperCase()}`,
                `${String(item.year)}`,
              ];
            } else {
              return String(item.month).toUpperCase();
            }
          })
        );

        setTotalTestsPerMonth(data.map((item) => Number(item.count)));
        console.log('total', total);
        console.log('data', data);
        setTotal(total);
        setFiltersData(response.data.filters);
        setCountLoading((prev) => prev + 1);
      }
    } catch (error) {
      setCategories(['NÃO HÁ DADOS']);
      setTotalTestsPerMonth([]);
      setTotal(null);
    }
  };

  const getAvaliacaoGeralChartData = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/avaliacao-percentagem',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data.data; // Acessa o objeto de dados

      if (!data || (data.adequada === 0 && data.inadequada === 0)) {
        setavaliacaoGeralChartData([0, 0]);
        setavaliacaoGeralLabels([]);
      } else {
        setavaliacaoGeralChartData([data.adequada, data.inadequada]);
        setavaliacaoGeralLabels([
          `Adequada (${data.adequadaTotal})`,
          `Inadequada (${data.inadequadaTotal})`,
        ]);
      }
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching data:', error);
      setCountLoading((prev) => prev + 1);
      setavaliacaoGeralChartData([]);
      setavaliacaoGeralLabels([]);
    }
  };
  const [hpvColpoPaginationState, setHpvColpoPaginationState] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  
  const [hpvColpoDataTable, setHpvColpoDataTable] = useState({
    data: [],
    totals: {
      totalMulheresComHpv16_18: 0,
      totalColposcopias: 0,
      totalColposcopiasARealizar: 0
    },
    totalItems: 0,
    localizacaoType: '',
  });  
  
  const getHpvColpoDataTable = async (cleared: boolean, payload: any, newPaginationState: typeof hpvColpoPaginationState) => {
    try {
      setLoading(true);
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/hpv-colposcopia-table',
        {
          params: cleared
            ? { page: newPaginationState.currentPage, limit: newPaginationState.itemsPerPage }
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                page: newPaginationState.currentPage,
                limit: newPaginationState.itemsPerPage,
              },
        }
      );
  
      const { data, totals, totalItems, localizacaoType } = response.data; 
  
      setHpvColpoDataTable({
        data,
        totals,
        totalItems,
        localizacaoType, 
      });
  
      setHpvColpoPaginationState({
        currentPage: newPaginationState.currentPage,
        itemsPerPage: newPaginationState.itemsPerPage,
        totalItems,
      });
  
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching hpv-colpo data:', error);
      setCountLoading((prev) => prev + 1);
    } finally {
      setLoading(false);
    }
  };  
  const handleHpvColpoPageChange = (newPage: number) => {
    setHpvColpoPaginationState(prevState => {
      const newState = {
        ...prevState,
        currentPage: newPage,
      };
      getHpvColpoDataTable(false, payload, newState);
      return newState;
    });
  };
  
  const handleHpvColpoRowsPerPageChange = (newRowsPerPage: number) => {
    setHpvColpoPaginationState(prevState => {
      const newState = {
        ...prevState,
        currentPage: 1,
        itemsPerPage: newRowsPerPage,
      };
      getHpvColpoDataTable(false, payload, newState);
      return newState;
    });
  };
  
    
  
  

  const [citoColpoPaginationState, setCitoColpoPaginationState] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  
  const [citoColpoDataTable, setCitoColpoDataTable] = useState({
    data: [],
    totals: {
      totalCitologiasAlteradas: 0,
      totalColposcopiasRealizadas: 0,
      totalColposcopiasARealizar: 0
    },
    totalItems: 0,
    localizacaoType: '',
  });
  
  const getCitoColpoDataTable = async (cleared: boolean, payload: any, newPaginationState: typeof citoColpoPaginationState) => {
    try {
      setLoading(true);
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/citologia-colposcopia-table',
        {
          params: cleared
            ? { page: newPaginationState.currentPage, limit: newPaginationState.itemsPerPage }
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                page: newPaginationState.currentPage,
                limit: newPaginationState.itemsPerPage,
              },
        }
      );
  
      const { data, totals, totalItems, localizacaoType } = response.data;
  
      setCitoColpoDataTable({
        data,
        totals,
        totalItems,
        localizacaoType,
      });
  
      setCitoColpoPaginationState({
        currentPage: newPaginationState.currentPage,
        itemsPerPage: newPaginationState.itemsPerPage,
        totalItems,
      });
  
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching cito-colpo data:', error);
      setCountLoading((prev) => prev + 1);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCitoColpoPageChange = (newPage: number) => {
    setCitoColpoPaginationState(prevState => {
      const newState = {
        ...prevState,
        currentPage: newPage,
      };
      getCitoColpoDataTable(false, payload, newState);
      return newState;
    });
  };
  
  const handleCitoColpoRowsPerPageChange = (newRowsPerPage: number) => {
    setCitoColpoPaginationState(prevState => {
      const newState = {
        ...prevState,
        currentPage: 1,
        itemsPerPage: newRowsPerPage,
      };
      getCitoColpoDataTable(false, payload, newState);
      return newState;
    });
  };
  
  


  const getRealizarBiopsiaData = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/realizar-biopsia',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data?.data || {};

      const realizarBiopsia = {
        totalSim: data.totalSim || 0,
        totalNao: data.totalNao || 0,
        simPercent: data.simPercent || 0,
        naoPercent: data.naoPercent || 0,
      };

      setRealizarBiopsiaData(realizarBiopsia);
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      setCountLoading((prev) => prev + 1);
      setRealizarBiopsiaData({
        totalSim: 0,
        totalNao: 0,
        simPercent: 0,
        naoPercent: 0,
      });
    }
  };

  const [tipoAchadosPaginationState, setTipoAchadosPaginationState] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  
  const [tipoAchadosData, setTipoAchadosData] = useState<any>({
    data: [],
    totals: {
      totalNormais: 0,
      totalAnormais: 0,
    },
    localizacaoType: '',
  });

  const emptyTipoDeAchados = !tipoAchadosData || tipoAchadosData.length === 0;
  
  const getTipoAchadosData = async (cleared: boolean, payload: any, newPaginationState: typeof tipoAchadosPaginationState) => {
    try {
      setLoading(true);
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/achados-municipio',
        {
          params: cleared
            ? { page: newPaginationState.currentPage, limit: newPaginationState.itemsPerPage }
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                page: newPaginationState.currentPage,
                limit: newPaginationState.itemsPerPage,
              },
        }
      );
  
      const { data, totals, totalItems,localizacaoType } = response.data;
  
      setTipoAchadosData({
        data,
        totals,
        localizacaoType,
      });
  
      setTipoAchadosPaginationState({
        currentPage: newPaginationState.currentPage,
        itemsPerPage: newPaginationState.itemsPerPage,
        totalItems,
      });
  
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching tipo-achados data:', error);
      setTipoAchadosData({ data: [], totals: { totalNormais: 0, totalAnormais: 0 } });
      setCountLoading((prev) => prev + 1);
    } finally {
      setLoading(false);
    }
  };
  
  const handleTipoAchadosPageChange = (newPage: number) => {
    setTipoAchadosPaginationState(prevState => {
      const newState = {
        ...prevState,
        currentPage: newPage,
      };
      getTipoAchadosData(false, payload, newState);
      return newState;
    });
  };
  
  const handleTipoAchadosRowsPerPageChange = (newRowsPerPage: number) => {
    setTipoAchadosPaginationState(prevState => {
      const newState = {
        ...prevState,
        currentPage: 1,
        itemsPerPage: newRowsPerPage,
      };
      getTipoAchadosData(false, payload, newState);
      return newState;
    });
  };
  
  
  /* 
 reports: 
 const redirectToReports = () => {
    localStorage.removeItem('result');
    localStorage.removeItem('typeVirus');
    localStorage.removeItem('reasonNotProcedure');
    localStorage.removeItem('collectionOrigin');
    //return
    navigate('/relatorios/colposcopia', {
      state: {
        origin: 'tables',
        table: 'totalValue',
        payload: payload,
        panelClicked: 'totalTests',
      },
    });
  }; */

  useEffect(() => {
    if (isPadrao && estadosSelected.length === 0 && municipiosSelected.length === 0 && estabelecimentosSelected.length === 0) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
      setEstabelecimentosSelected(
        filtersData?.estabelecimentos ? [filtersData?.estabelecimentos] : []
      );
    }

    if (isMunicipal && estadosSelected.length === 0 && municipiosSelected.length === 0) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
    }

    if (isEstadual && estadosSelected.length === 0) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
    }
  }, [filtersData]);


  const getAllCharts = ({ cleared, payload }) => {
    setCountLoading(0);
    localStorage.setItem('coleta_inicio', payload?.periodo_inicio);
    localStorage.setItem('coleta_fim', payload?.periodo_fim);
    setTotalTestsPerMonth([]);
    // setCategories([]);

    setTotal('');
    getAvaliacaoGeralChartData(cleared, payload);

    getTestesPerMonth(cleared, payload);
    getHpvColpoDataTable(cleared, payload, hpvColpoPaginationState);
    getCitoColpoDataTable(cleared, payload, citoColpoPaginationState);
    getRealizarBiopsiaData(cleared, payload);
    getTipoAchadosData(cleared, payload, tipoAchadosPaginationState);
  };

  useEffect(() => {
    if (isPadrao && estabelecimentosSelected.length === 0) {
      setEstabelecimentosSelected([userToken.estabelecimentoId]);

      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,
            origem: 'Biópsia',
            estabelecimentos: userToken.estabelecimentoId,
          },
        },
      });
    } else {
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,
            origem: 'Biópsia',
          },
        },
      });
    }
  }, []);
  
  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);

  /* useEffect(() => {
    if (!userToken.permissoes.includes('VIEW_DASHBOARD_COLPOSCOPIA')) {
      navigate('/home');
    }
  }, []); */

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          setPeriodToDisplay={setPeriodToDisplay}
          payload={payload}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>

        {/* <div className={styles.filtersInfo}>
          Filtros aplicados: Estado: Todos - Município: Todos - Estabelecimento:
          Todos - Equipe: Todas - Período: Últimos 12 meses.
        </div> */}
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Colposcopia <br />
            Quantidade de Exames Realizados
          </Typography>
          <p style={{ margin: '8px 0 0 0', fontSize: '12px', color: '#9E9E9E' }}>
          {valueInputPeriod === 'Todos' || !valueInputPeriod
            ? 'Todos os períodos'
            : valueInputPeriod}
        </p>
          <Typography variant="h5" fontWeight="bold">
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={null}
        />

        <p
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            bottom: '0',
            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableCard
            title={`Exames de Colposcopia`}
            subtitle={'Tipos de Achados'}
            fullWidth
            valueInputPeriod={valueInputPeriod}
          >
                                {emptyTipoDeAchados && (
                       <div
                       style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         height: '100%',
                         marginTop: '-100px', 
                       }}
                     >
          <EmptyData />
        </div>
      )}

{!emptyTipoDeAchados && (<TipoAchadosStackedBarsChart
  data={tipoAchadosData}
  totalItems={tipoAchadosPaginationState.totalItems}
  currentPage={tipoAchadosPaginationState.currentPage}
  itemsPerPage={tipoAchadosPaginationState.itemsPerPage}
  onPageChange={handleTipoAchadosPageChange}
  onRowsPerPageChange={handleTipoAchadosRowsPerPageChange}
/>
)}
            <p
              style={{
                marginBottom: '16px',
                marginRight: '16px',
                bottom: '0',
                right: '0',
                position: 'absolute',
                fontSize: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte dos Dados: JORDANA
            </p>
          </TableCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            valueInputPeriod={valueInputPeriod}
            title="Exames de Colposcopia"
            subtitle="Avaliação Geral da Colposcopia"
          >
                        {emptyAvaliacaoGeral ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  marginTop: '-20px', // Ajuste a margem superior conforme necessário
                }}
              >
                <EmptyData />
              </div>
            ) : (
              <AvaliacaoGeral
                data={avaliacaoGeralChartData}
                labels={avaliacaoGeralLabels}
                colors={['#775DD0', '#CC64DD']}
              />
            )}

          </ChartCard>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          gap="16px"
          display="flex"
          flexDirection="column"
        >
          <CardRealizarBiopsia
            title="Exames de Colposcopia (Realizar Biópsia - Sim)"
            period={
              valueInputPeriod === 'Todos' || !valueInputPeriod
                ? 'Todos os períodos'
                : valueInputPeriod
            }
            color="#ED6C02"
            number={realizarBiopsiaData.totalSim}
            percentage={realizarBiopsiaData.simPercent}
          />
          <CardRealizarBiopsia
            title="Exames de Colposcopia (Realizar Biópsia - Não)"
            period={
              valueInputPeriod === 'Todos' || !valueInputPeriod
                ? 'Todos os períodos'
                : valueInputPeriod
            }
            color="#4CAF50"
            number={realizarBiopsiaData.totalNao}
            percentage={realizarBiopsiaData.naoPercent}
          />
        </Grid>

        {/* Adicionar aqui card + tableExamesColposcopia */}

        <Grid item xs={12}>
          <TableCard
            title={`Exames de Colposcopia`}
            subtitle={'Mulheres com HPV 16 ou 18 x Colposcopias Realizadas'}
            fullWidth
            valueInputPeriod={valueInputPeriod}
          >
            <TableHPVColpo
  data={hpvColpoDataTable.data}
  totals={hpvColpoDataTable.totals}
  totalItems={hpvColpoPaginationState.totalItems}
  currentPage={hpvColpoPaginationState.currentPage}
  itemsPerPage={hpvColpoPaginationState.itemsPerPage}
  onPageChange={handleHpvColpoPageChange}
  onRowsPerPageChange={handleHpvColpoRowsPerPageChange}
  localizacaoType={hpvColpoDataTable.localizacaoType}
/>
            <p
              style={{
                marginBottom: '16px',
                marginRight: '16px',
                bottom: '0',
                right: '0',
                position: 'absolute',
                fontSize: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte dos Dados: JORDANA
            </p>
          </TableCard>
        </Grid>

        <Grid item xs={12}>
          <TableCard
            title={`Exames de Colposcopia`}
            subtitle={
              'Mulheres com Citologia Insatisfatória ou Com Alterações Epteliais x Colposcopias Realizadas'
            }
            fullWidth
            valueInputPeriod={valueInputPeriod}
          >
<TableCitoColpo
  data={citoColpoDataTable.data}
  totals={citoColpoDataTable.totals}
  totalItems={citoColpoPaginationState.totalItems}
  currentPage={citoColpoPaginationState.currentPage}
  itemsPerPage={citoColpoPaginationState.itemsPerPage}
  onPageChange={handleCitoColpoPageChange}
  onRowsPerPageChange={handleCitoColpoRowsPerPageChange}
  localizacaoType={citoColpoDataTable.localizacaoType}
/>
            <p
              style={{
                marginBottom: '16px',
                marginRight: '16px',
                bottom: '0',
                right: '0',
                position: 'absolute',
                fontSize: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte dos Dados: JORDANA
            </p>
          </TableCard>
        </Grid>

        {['SuperAdmin', 'Admin Estadual'].includes(
          userToken.permissao_atual.nome
        ) && <></>}
      </Grid>

      {['SuperAdmin', 'Admin Estadual'].includes(
        userToken.permissao_atual.nome
      ) && (
        <p
          style={{
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.38)',
            width: '100%',
            textAlign: 'right',
            marginTop: '24px',
            marginBottom: '40px',
          }}
        >
          * O quantitativo é atualizado por automatização do sistema com o GAL
        </p>
      )}
    </Box>
  );
}
