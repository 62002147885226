import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import { SelectWithSearch } from '../../../../components/SelectWithSearch';
import MultipleFilters from '../../../../components/MultipleFilters';
import useHandleChangeFilters from './hooks/useHandleChangesFilters';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';

import { lugol } from '../../../../mocks/lugol-mock';
import { tiposAchados } from '../../../../mocks/tipos-achados-mock';
import { zt } from '../../../../mocks/zt-mock';
import { useContext, useEffect, useState } from 'react';
import api from '../../../../api';
import { getUserToken } from '../../../../lib/auth';
import { getCidades } from '../../../../lib/getCidadesEstabelecimentos';
import useGetDetailsFilters from '../../ReportsHpv/FiltersReportsHpv/hooks/useGetDetailsFilters';
import useRequestFilter from './hooks/useRequestFilter';
import useSetPayload from './hooks/useSetPayload';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import useClearValues from './hooks/useClearValues';
import { PerfilContext } from '../../../../context/PerfilContext';
import { LoadingContext } from '../../../../context/LoadingContext';

const alteracoes = [
  {
    label: 'Com Alterações',
    value: 2,
  },
  {
    label: 'Sem Alterações',
    value: 1,
  },
];

const avaliacao = [
  {
    label: 'Adequada',
    value: 1,
  },
  {
    label: 'Inadequada',
    value: 2,
  },
];

const visibilidadeEscamocolunar = [
  { label: 'Completamente Visivel', value: 1 },
  { label: 'Nao Visivel', value: 2 },
  { label: 'Parcialmente Visivel', value: 3 },
];

const testeSchiller = [
  { label: 'Negativo', value: false },
  { label: 'Positivo', value: true },
];

const realizarBiopsia = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

const FiltersReportsColposcopia = ({
  errors,
  estadosSelected,
  setEstadosSelected,
  setTableData,
  setShowTableData,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  periodDtResultado,
  periodDtColposcopia,
  setPeriodDtResultado,
  control,
  handleSubmit,
  setValue,

  setSelectedResultado,
  setDataResultadoIni,
  setDataResultadoFinal,
  selectedResultado,
  watch,
  showTableData,
  listFilter,
  setListFilter,
  setListFilterShow,
  dtResultadoIni,
  dtResultadoFinal,
  setSelectedOrigem,
  setSelectedCarater,
  setSelectedEscamosa,
  setSelectedGlandular,
  selectedGlandular,
  selectedEscamosa,
  selectedCarater,
  selectedOrigem,
  setPeriodDtColposcopia,
  setDtColposcopiaFinal,
  setDtColposcopiaIni,
  dtColposcopiaIni,
  dtColposcopiaFinal,

  setDisableOtherInputs,
  disableOtherInputs,
  setError,

  setSelectedOptionsAvaliacao,
  setSelectedOptionsVisibilidade,
  setSelectedOptionsZt,
  setSelectedOptionsTiposAchados,
  setSelectedOptionsLugol,
  setSelectedOptionsSchiller,
  setSelectedOptionsBiopsia,
  setSelectedMacroscopico,

  selectedOptionsAvaliacao,
  selectedOptionsVisibilidade,
  selectedOptionsZt,
  selectedOptionsTiposAchados,
  selectedOptionsLugol,
  selectedOptionsSchiller,
  selectedOptionsBiopsia,
  selectedOptionsAlteracoes,
  setSelectedOptionsAlteracoes,
  selectedMacroscopico,
  clearValuesInputs,

  setDisabledMunicipioInput,
  setDisabledEstabelecimentoInput,
  disableMunicipioInput,
  disableEstabelecimentoInput,
  disableInputEstado,
  setDisableInputEstado,
  clearErrors,
}) => {
  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,

    optionsLaboratorios,
    allLaboratoriosOptions,
    searchLaboratorios,
    anchorElLaboratorios,

    setOptionsLaboratorios,
    setAllLaboratoriosOptions,
    setSearchLaboratorios,
    setAnchorLaboratorios,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    errorsFields,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setErrorsFields,

    laboratoriosSelected,
    setLaboratoriosSelected,
  } = useStatesFields();
  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [disabledProfissionalResultado, setDisabledProfissionalResultado] =
    useState(false);

  const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
    useState(false);

  const { validationSubmit } = useActions();

  const [responsaveis, setResponsaveis] = useState<any>([]);

  const [errorInputDate, setErrorInputDate] = useState<any>();

  const { handleSetFilter } = useHandleChangeFilters({ setListFilter });

  const handleSetPeriodDtResultado = (e) => {
    setPeriodDtResultado(e.target.value);
    setDataResultadoFinal(null);
    setDataResultadoIni(null);
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_coleta_inicio');
    });
  };
  const handleSetPeriodDtColposcopia = (e) => {
    setPeriodDtColposcopia(e.target.value);
    setDtColposcopiaFinal(null);
    setDtColposcopiaIni(null);
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'data_ini_colposcopia');
    });
    handleSetFilter('', 'data_ini_colposcopia');
    handleSetFilter('', 'data_fin_colposcopia');
  };

  const handleSetSelectedOptions =
    ({ setSelected, description, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${description}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${description}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), fieldName);
    };

  const getReportsResultadosColposcopia = async (payloadToRequest) => {
    const response = await api.get('/reports/visualizar/colposcopia', {
      params: {
        ...payloadToRequest,
      },
    });
    setTableData(response.data);
    if (response.data) {
      setLoading(false);
    }
  };

  const userToken = getUserToken();

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const isLaboratorio =
    getUserToken().permissao_atual.contexto === 'LABORATÓRIO';

  const setFiltersWhenPadrao = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);

    setDisabledMunicipioInput(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        const laboratorio = {
          value: userToken.estabelecimentoId,
          label: userToken.estabelecimento_saude?.nome_fantasia,
        };

        if (!isLaboratorio) {
          payload = {
            page: 1,
            size: 25,
            estado: 'PE',
            municipioId: municipio[0].id,
            estabelecimento_saude: userToken.estabelecimentoId,
          };
          setEstabelecimentosSelected([userToken.estabelecimentoId]);
          setDisabledEstabelecimentoInput(true);

          setListFilterShow([
            {
              inputName: 'estado',
              value: 'Estado: PE',
            },
            {
              inputName: 'municipio',
              value: `Municipio: ${userToken.endereco.municipio_nome}`,
            },
            {
              inputName: 'laboratorio',
              value: '',
            },
            {
              inputName: 'estabelecimento_saude',
              value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
            },
            { inputName: 'resultado', value: '' },
            { inputName: 'profissionalLaudo', value: '' },
            { inputName: 'data_ini_coleta', value: '' },
            { inputName: 'data_fin_coleta', value: '' },
            { inputName: 'data_ini_resultado', value: '' },
            { inputName: 'data_fin_resultado', value: '' },
            { inputName: 'data_ini_colposcopia', value: '' },
            { inputName: 'data_fin_colposcopia', value: '' },
            { inputName: 'macroscopico', value: '' },
            { inputName: 'aval_geral', value: '' },
            { inputName: 'visibilidade', value: '' },
            { inputName: 'zt', value: '' },
            { inputName: 'achados', value: '' },
            { inputName: 'lugol', value: '' },
            { inputName: 'schiller', value: '' },
            { inputName: 'biopsia', value: '' },
            { inputName: 'profissionalId', value: '' },
          ]);

          setListFilter([
            { inputName: 'estado', value: 'Estado: PE' },
            {
              inputName: 'municipio',
              value: `Municipio: ${userToken.endereco.municipio_nome}`,
            },
            {
              inputName: 'laboratorio',
              value: ``,
            },
            {
              inputName: 'estabelecimento_saude',
              value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
            },
            { inputName: 'resultado', value: '' },
            { inputName: 'profissionalLaudo', value: '' },
            { inputName: 'data_ini_coleta', value: '' },
            { inputName: 'data_fin_coleta', value: '' },
            { inputName: 'data_ini_resultado', value: '' },
            { inputName: 'data_fin_resultado', value: '' },
            { inputName: 'data_ini_colposcopia', value: '' },
            { inputName: 'data_fin_colposcopia', value: '' },
            { inputName: 'macroscopico', value: '' },
            { inputName: 'aval_geral', value: '' },
            { inputName: 'visibilidade', value: '' },
            { inputName: 'zt', value: '' },
            { inputName: 'achados', value: '' },
            { inputName: 'lugol', value: '' },
            { inputName: 'schiller', value: '' },
            { inputName: 'biopsia', value: '' },
            { inputName: 'profissionalId', value: '' },
          ]);
        } else {
          setLaboratoriosSelected([laboratorio]);
          payload = {
            page: 1,
            size: 25,
            estado: 'PE',
            municipioId: municipio[0].id,
            laboratorioId: userToken.estabelecimentoId,
          };
          setDisabledLaboratorioResultado(true);
          setDisabledEstabelecimentoInput(false);

          setListFilterShow([
            {
              inputName: 'estado',
              value: 'Estado: PE',
            },
            {
              inputName: 'municipio',
              value: `Municipio: ${userToken.endereco.municipio_nome}`,
            },
            {
              inputName: 'laboratorio',
              value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
            },
            { inputName: 'estabelecimento_saude', value: '' },
            { inputName: 'resultado', value: '' },
            { inputName: 'profissionalLaudo', value: '' },
            { inputName: 'data_ini_coleta', value: '' },
            { inputName: 'data_fin_coleta', value: '' },
            { inputName: 'data_ini_resultado', value: '' },
            { inputName: 'data_fin_resultado', value: '' },
            { inputName: 'data_ini_colposcopia', value: '' },
            { inputName: 'data_fin_colposcopia', value: '' },
            { inputName: 'macroscopico', value: '' },
            { inputName: 'aval_geral', value: '' },
            { inputName: 'visibilidade', value: '' },
            { inputName: 'zt', value: '' },
            { inputName: 'achados', value: '' },
            { inputName: 'lugol', value: '' },
            { inputName: 'schiller', value: '' },
            { inputName: 'biopsia', value: '' },
            { inputName: 'profissionalId', value: '' },
          ]);

          setListFilter([
            { inputName: 'estado', value: 'Estado: PE' },
            {
              inputName: 'municipio',
              value: `Municipio: ${userToken.endereco.municipio_nome}`,
            },
            {
              inputName: 'laboratorio',
              value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
            },
            { inputName: 'estabelecimento_saude', value: '' },
            { inputName: 'resultado', value: '' },
            { inputName: 'profissionalLaudo', value: '' },
            { inputName: 'data_ini_coleta', value: '' },
            { inputName: 'data_fin_coleta', value: '' },
            { inputName: 'data_ini_resultado', value: '' },
            { inputName: 'data_fin_resultado', value: '' },
            { inputName: 'data_ini_colposcopia', value: '' },
            { inputName: 'data_fin_colposcopia', value: '' },
            { inputName: 'macroscopico', value: '' },
            { inputName: 'aval_geral', value: '' },
            { inputName: 'visibilidade', value: '' },
            { inputName: 'zt', value: '' },
            { inputName: 'achados', value: '' },
            { inputName: 'lugol', value: '' },
            { inputName: 'schiller', value: '' },
            { inputName: 'biopsia', value: '' },
            { inputName: 'profissionalId', value: '' },
          ]);
        }

        resolve(payload);
      }
    });
  };

  const setFiltersWhenMunicipal = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);
    setDisabledMunicipioInput(true);
    setDisabledEstabelecimentoInput(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        //setEstabelecimentosSelected([userToken.estabelecimentoId]);

        payload = {
          page: 1,
          size: 25,
          estado: 'PE',
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: ``,
          },

          { inputName: 'resultado', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini_coleta', value: '' },
          { inputName: 'data_fin_coleta', value: '' },
          { inputName: 'data_ini_resultado', value: '' },
          { inputName: 'data_fin_resultado', value: '' },
          { inputName: 'data_ini_colposcopia', value: '' },
          { inputName: 'data_fin_colposcopia', value: '' },
          { inputName: 'macroscopico', value: '' },
          { inputName: 'aval_geral', value: '' },
          { inputName: 'visibilidade', value: '' },
          { inputName: 'zt', value: '' },
          { inputName: 'achados', value: '' },
          { inputName: 'lugol', value: '' },
          { inputName: 'schiller', value: '' },
          { inputName: 'biopsia', value: '' },
          { inputName: 'profissionalId', value: '' },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: ``,
          },

          { inputName: 'resultado', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini_coleta', value: '' },
          { inputName: 'data_fin_coleta', value: '' },
          { inputName: 'data_ini_resultado', value: '' },
          { inputName: 'data_fin_resultado', value: '' },
          { inputName: 'data_ini_colposcopia', value: '' },
          { inputName: 'data_fin_colposcopia', value: '' },
          { inputName: 'macroscopico', value: '' },
          { inputName: 'aval_geral', value: '' },
          { inputName: 'visibilidade', value: '' },
          { inputName: 'zt', value: '' },
          { inputName: 'achados', value: '' },
          { inputName: 'lugol', value: '' },
          { inputName: 'schiller', value: '' },
          { inputName: 'biopsia', value: '' },
          { inputName: 'profissionalId', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const isMedicoLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 7;

  const isAdminLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.nome === 'Admin Estabelecimento';

  const isOutrosLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 8;

  const isUserLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO';

  const setFiltersWhenMedicoLaboratorio = () => {
    setDisableInputEstado(true);
    setDisabledLaboratorioResultado(true);
    setDisabledProfissionalResultado(true);
    setShowTableData(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      setOptionsMunicipios(optionsCidades);

      const getProfissional = await api.get(
        `/profissionais/estabelecimento/${userToken.estabelecimentoId}`
      );

      const dataOffProfissional = getProfissional.data?.find(
        (prof) => prof.idpessoa === userToken.pessoa.id
      );

      setValue('profissional_responsavel_laudo', {
        ...dataOffProfissional,
        label: dataOffProfissional.nomeprofissional,
      });

      setResponsaveis([dataOffProfissional]);

      const labs = [
        {
          id: 1474,
          nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
          cnes: 2712105,
          label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
        },
        {
          id: 4691,
          nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
          cnes: 6897029,
          label: 'US 376 POLICLINICA SALOMAO KELNER',
        },
        {
          id: 158,
          nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
          cnes: '0000647',
          label: 'US 376 POLICLINICA SALOMAO KELNER',
        },
        {
          id: 61,
          nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
          cnes: '0000779',
          label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
        },
      ] as any;

      setSelectedOptionsLaboratorio([
        {
          label: userToken.estabelecimento_saude.nome_fantasia,
          value: userToken.estabelecimentoId,
        },
      ]);
      setSelectedLaboratorio([
        {
          label: userToken.estabelecimento_saude.nome_fantasia,
          value: userToken.estabelecimentoId,
        },
      ]);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
        profissionalId: dataOffProfissional?.idprofissional,
        laboratorioId: userToken.estabelecimentoId,
      };

      setListFilterShow([
        {
          inputName: 'estado',
          value: 'Estado: PE',
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'laboratorio_responsavel',
          value: `Laboratório Responsável pelo Resultado: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },

        {
          inputName: 'profissional_responsavel',
          value: `Profissional Responsável pelo Resultado: ${dataOffProfissional?.nomeprofissional}`,
        },
      ]);

      setListFilter([
        { inputName: 'estado', value: 'Estado: PE' },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'laboratorio_responsavel',
          value: `Laboratório Responsável pelo Resultado: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },
        {
          inputName: 'profissional_responsavel',
          value: `Profissional Responsável pelo Resultado: ${dataOffProfissional?.pessoa?.nome}`,
        },
        { inputName: 'resultado', value: '' },
        { inputName: 'profissionalLaudo', value: '' },
        { inputName: 'data_ini', value: '' },
        { inputName: 'data_fin', value: '' },
        { inputName: 'search', value: '' },
      ]);
      resolve(payload);
    });
  };

  const setFiltersWhenAdminOrOthersLaboratorio = () => {
    setDisableInputEstado(true);
    setDisabledLaboratorioResultado(true);
    setShowTableData(false);
    setDisabledMunicipioInput(false);

    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        // setEstabelecimentosSelected([userToken.estabelecimentoId]);

        const labs = [
          {
            id: 1474,
            nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
            cnes: 2712105,
            label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
          },
          {
            id: 4691,
            nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
            cnes: 6897029,
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 158,
            nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
            cnes: '0000647',
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 61,
            nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
            cnes: '0000779',
            label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
          },
        ] as any;

        const labFromUser = labs.find(
          (lab) => lab.id === userToken.estabelecimentoId
        );

        setSelectedOptionsLaboratorio(
          labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        );
        setSelectedLaboratorio([
          { value: labFromUser.id, label: labFromUser.nome },
        ]);

        payload = {
          page: 1,
          size: 25,
          estados: 'PE',
          laboratorioId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissional_responsavel', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputName: 'search', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const setFiltersWhenEstadual = async (userToken) => {
    setShowTableData(false);
    // setDisableInputEstado(true);

    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
      };

      setListFilterShow([
        {
          inputName: 'estados',
          value: 'Estado: PE',
        },
      ]);

      handleSetFilter('Estado: PE', 'estados');
      // setLoading(false);

      // reject();
    });
  };

  const requestDataByUserPerfil = async () => {
    if (
      userToken.permissao_atual.id === 1 ||
      userToken.permissao_atual.id === 6 ||
      userToken.permissao_atual.id === 7 ||
      userToken.permissao_atual.id === 8 ||
      userToken.permissao_atual.id === 9 ||
      userToken.permissao_atual.id === 10
    ) {
      if (!isUserLaboratorio) {
        setFiltersWhenPadrao(userToken).then((payload) => {
          getReportsResultadosColposcopia(payload);
        });
      } else {
        if (isMedicoLaboratorio) {
          setFiltersWhenMedicoLaboratorio().then((payload) => {
            getReportsResultadosColposcopia(payload);
          });
        }

        if (isAdminLaboratorio || isOutrosLaboratorio) {
          setFiltersWhenAdminOrOthersLaboratorio();
          setLoading(true);
        }
      }
    }

    if (userToken.permissao_atual.id === 3) {
      setFiltersWhenMunicipal(userToken).then((payload) => {
        getReportsResultadosColposcopia(payload);
      });
    }

    if (userToken.permissao_atual.id === 4) {
      setFiltersWhenEstadual(userToken);
      setLoading(true);
    }

    if (userToken.permissao_atual.id === 2) {
      setLoading(false);
    }

    return true;
  };

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    selectedResultado,

    dtResultadoIni,
    dtResultadoFinal,
    selectedOptionsAlteracoes,
    selectedOptionsAvaliacao,
    selectedOptionsVisibilidade,

    dtColposcopiaIni,
    dtColposcopiaFinal,

    selectedOptionsZt,

    selectedOptionsTiposAchados,
    selectedOptionsLugol,
    selectedOptionsSchiller,
    selectedOptionsBiopsia,
    selectedLaboratorio,
  });

  const { loadReportsColposcopia } = useRequestFilter({ setTableData });

  const fnWhenFormIsValid = async () => {
    setTableData([]);

    setListFilterShow(listFilter);

    setShowTableData(true);

    await setPayload().then((payload) => {
      try {
        loadReportsColposcopia(payload);
      } catch (error) {}
    });
  };

  const { getPermissao } = useContext(PerfilContext);

  const permissao = getPermissao();

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    watch,
    loadReportsColposcopia,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    showTableData,
    setShowTableData,
    setTableData,
    setErrorsFields,
    setListFilterShow,
    setSelectedResultado,
    setDtColposcopiaIni,
    setDtColposcopiaFinal,
    setDataResultadoIni,
    setDataResultadoFinal,
    setSelectedOptionsAvaliacao,
    setSelectedOptionsVisibilidade,
    setSelectedOptionsZt,
    setSelectedOptionsTiposAchados,
    setSelectedOptionsLugol,
    setSelectedOptionsSchiller,
    setSelectedOptionsBiopsia,
    setSelectedOptionsAlteracoes,
    setSelectedOptionsLaboratorio,
    setSelectedLaboratorio,
    setPayload,
  });

  const onSubmit = async () => {
    validationSubmit({
      fnWhenFormIsValid,
      estadosSelected,
      municipiosSelected,
      estabelecimentosSelected,
      setErrorsFields,
      setDisableOtherInputs,
      watch,
      setError,
      clearErrors,
    });
  };

  useEffect(() => {
    // const state: any = location.state;
    async function loadResponsaveis() {
      if (isMedicoLaboratorio) {
        return;
      } else {
        const arrayLaboratorios = selectedLaboratorio?.map((lab) => lab.value);
        const response = await api.get(
          '/dashboard/exames/colposcopia/panel/profissionais-responsavel',
          {
            params: {
              estados:
                estadosSelected.length > 0
                  ? estadosSelected.join(', ')
                  : undefined,
              municipios:
                municipiosSelected.length > 0
                  ? municipiosSelected.join(', ')
                  : undefined,
              laboratorioId:
                arrayLaboratorios?.length > 0
                  ? arrayLaboratorios.join(', ')
                  : undefined,
              periodo_inicio: dtResultadoIni
                ? moment(dtResultadoIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : undefined,
              periodo_fim: dtResultadoFinal
                ? moment(dtResultadoFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : undefined,
            },
          }
        );

        const profissionais = response.data.data.map((profissional) => {
          return {
            idprofissional: profissional?.prof_id,
            nomeprofissional: `${profissional?.pessoa.nome}`,
            label: `${profissional?.pessoa.nome}`,
          };
        });

        setResponsaveis(profissionais);
      }

      // if (isAdminMunicipal) {
      //   const user: any = getUserToken();
      //   const idMunicipio = user.estabelecimento_saude?.endereco?.municipio?.id;
      //
      //   setResponsaveis(profissionais.data);
      // } else {
      //
      //   setResponsaveis(profissionais.data);
      // }
    }

    if (selectedLaboratorio?.length === 1) {
      loadResponsaveis();
    }
  }, [selectedLaboratorio]);

  useEffect(() => {
    if (
      (optionsMunicipios?.length > 0 &&
        municipiosSelected?.length === optionsMunicipios?.length) ||
      municipiosSelected.length > 0
    ) {
      setDisableOtherInputs(false);
    }
  }, [optionsMunicipios, municipiosSelected]);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    // if (origin === 'tables') {
    //   setLoading(true);
    //   setFiltersToRequestByTable().then((payloadToRequest) => {
    //     getReportsTracking(payloadToRequest);
    //     setUsePayloadFromCharts(true);
    //   });

    //   window.history.replaceState({}, '');
    // } else {
    //   setLoading(true);
    //   requestDataByUserPerfil();
    // }
    requestDataByUserPerfil();
  }, [origin]);

  useEffect(() => {
    const dtIni = moment(dtColposcopiaIni, 'DD-MM-YYYY');
    const dtFin = moment(dtColposcopiaFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDate({
        fin: 'Data final deve ser maior que a data inicial',
        ini: '',
      });
    } else {
      setErrorInputDate('');
    }
  }, [dtColposcopiaIni, dtColposcopiaFinal]);

  useEffect(() => {
    if (
      selectedLaboratorio?.length === 0 ||
      selectedOptionsLaboratorio?.length === 0
    ) {
      setValue('profissionalId', []);
      setResponsaveis([]);
    }
  }, [selectedLaboratorio, selectedOptionsLaboratorio]);

  const searchInput = watch('search');

  useEffect(() => {
    if (!searchInput) {
      clearErrors('search');
    }
  }, [searchInput]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <MultipleFilters
          disableRequiredEstabelecimento
          setOptionsLaboratorios={setOptionsLaboratorios}
          setAllLaboratoriosOptions={setAllLaboratoriosOptions}
          routeGetEstabelecimentos="/dashboard/exames/colposcopia/estabelecimentos"
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          disableInputEstado={disableInputEstado}
          setDisableInputEstado={setDisableInputEstado}
          handleSetFilter={handleSetFilter}
          // laboratorioField
          mdEstado={4}
          mdMunicipio={4}
          mdEstabelecimento={4}
          mdEquipe={3}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
          setDisableOtherInputs={setDisableOtherInputs}
          clearValuesInputs={clearValuesInputs}
        />
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Typography fontWeight="bold">Data da Colposcopia:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={periodDtColposcopia}
                onChange={handleSetPeriodDtColposcopia}
                row
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {periodDtColposcopia === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
                flexWrap="wrap"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      handleSetFilter(
                        moment(date).format('DD/MM/YYYY'),
                        'data_ini_colposcopia'
                      );
                      setDtColposcopiaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    value={
                      dtColposcopiaIni &&
                      moment(dtColposcopiaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColposcopiaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        error={!!errorInputDate?.ini}
                        helperText={errorInputDate?.ini}
                        label="Início da Data da Colposcopia"
                        size="small"
                        variant="outlined"
                        sx={{
                          // minWidth: '200px',
                          marginBottom: !!errorInputDate?.ini ? '-22px' : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      handleSetFilter(
                        moment(date).format('DD/MM/YYYY'),
                        'data_fin_colposcopia'
                      );
                      setDtColposcopiaFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    value={
                      dtColposcopiaFinal &&
                      moment(dtColposcopiaFinal, 'DD-MM-YYYY').isValid()
                        ? moment(dtColposcopiaFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data da Colposcopia"
                        error={!!errorInputDate?.fin}
                        helperText={errorInputDate?.fin}
                        size="small"
                        variant="outlined"
                        sx={{
                          // minWidth: '200px',
                          marginBottom: !!errorInputDate?.fin ? '-22px' : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {periodDtColposcopia === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="datePicker"
                    onChange={(date) => {
                      setDtColposcopiaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDtColposcopiaFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );

                      handleSetFilter(
                        moment(date).format('DD-MM-YYYY'),
                        'data_fin_colposcopia'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColposcopiaIni &&
                      moment(dtColposcopiaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColposcopiaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data da Colposcopia"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={alteracoes}
            disabled={disableOtherInputs}
            label="Exame macroscópico da vulva e região perianal"
            selectedOptions={selectedOptionsAlteracoes}
            setSelectedOptions={setSelectedOptionsAlteracoes}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsAlteracoes,
              description: 'Exame macroscópico da vulva e região perianal',
              fieldName: 'macroscopico',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={alteracoes}
            label="Exame macroscópico da vulva e região perianal"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={avaliacao}
            disabled={disableOtherInputs}
            label="Avaliação Geral da Colposcopia"
            selectedOptions={selectedOptionsAvaliacao}
            setSelectedOptions={setSelectedOptionsAvaliacao}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsAvaliacao,
              description: 'Avaliação Geral da Colposcopia',
              fieldName: 'aval_geral',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={avaliacao}
            label="Avaliação Geral da Colposcopia"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            disabled={disableOtherInputs}
            options={visibilidadeEscamocolunar}
            label="Visibilidade da junção escamocolunar"
            selectedOptions={selectedOptionsVisibilidade}
            setSelectedOptions={setSelectedOptionsVisibilidade}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsVisibilidade,
              description: 'Visibilidade da junção escamocolunar',
              fieldName: 'visibilidade',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={visibilidadeEscamocolunar}
            label="Visibilidade da junção escamocolunar"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={zt}
            disabled={disableOtherInputs}
            label="Zona de Transformação (ZT)"
            selectedOptions={selectedOptionsZt}
            setSelectedOptions={setSelectedOptionsZt}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsZt,
              description: 'Zona de Transformação (ZT)',
              fieldName: 'zt',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={zt}
            label="Zona de Transformação (ZT)"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={tiposAchados}
            label="Tipo de Achados"
            disabled={disableOtherInputs}
            selectedOptions={selectedOptionsTiposAchados}
            setSelectedOptions={setSelectedOptionsTiposAchados}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsTiposAchados,
              description: 'Tipo de Achados',
              fieldName: 'achados',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={tiposAchados}
            label="Tipo de Achados"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={lugol}
            disabled={disableOtherInputs}
            label="Captação da solução de lugol"
            selectedOptions={selectedOptionsLugol}
            setSelectedOptions={setSelectedOptionsLugol}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsLugol,
              description: 'Captação da solução de lugol',
              fieldName: 'lugol',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={lugol}
            label="Captação da solução de lugol"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={testeSchiller}
            disabled={disableOtherInputs}
            label="Teste de Schiller"
            selectedOptions={selectedOptionsSchiller}
            setSelectedOptions={setSelectedOptionsSchiller}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsSchiller,
              description: 'Teste de Schiller',
              fieldName: 'schiller',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={testeSchiller}
            label="Teste de Schiller"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={realizarBiopsia}
            label="Realizar Biópsia"
            disabled={disableOtherInputs}
            selectedOptions={selectedOptionsBiopsia}
            setSelectedOptions={setSelectedOptionsBiopsia}
            required
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptionsBiopsia,
              description: 'Realizar Biópsia',
              fieldName: 'biopsia',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
          {/* <SelectWithSearch
            options={realizarBiopsia}
            label="Realizar Biópsia"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12} md={12}>
          <SelectWithSearch
            options={optionsLaboratorios}
            isLaboratorio
            label="Laboratório Responsável pelo Laudo"
            disabled={disableOtherInputs || disabledLaboratorioResultado}
            selectedOptions={selectedOptionsLaboratorio}
            setSelectedOptions={setSelectedOptionsLaboratorio}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedLaboratorio,
              description: 'Laboratório Responsável pelo Laudo',
              fieldName: 'laboratorio_responsavel_laudo',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
          />
          {/* <SelectWithSearch
            options={alteracoes}
            label="Exame macroscópico da vulva e região perianal"
            setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="profissional_responsavel_laudo"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  data-testid="inputProfissionalResultadoId"
                  disabled={disableOtherInputs || disabledProfissionalResultado}
                  options={responsaveis}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleSetFilter(
                      `Profissional Responsável pelo Resultado: ${newValue?.nomeprofissional}`,
                      'profissionalId'
                    );
                  }}
                  noOptionsText={
                    selectedLaboratorio?.length > 1
                      ? 'Não há opções'
                      : 'Selecione ao menos um laboratório para visualizar a lista de profissionais'
                  }
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      data-testid="profissionalLaudo"
                      label="Profissional Responsável pelo Resultado"
                    />
                  )}
                />
              );
            }}
          />
          {/* <FormControl fullWidth>
            <InputLabel
              id="profissional-label"
              sx={{
                top: '-7px',
              }}
            >
              Profissional Responsável pelo Resultado
            </InputLabel>
            <Select
              labelId="profissional-label"
              id="profissional"
              //   value={profissional}
              label="Age"
              //   onChange={handleChange}
              size="small"
            >
              <MenuItem value="TODOOS">Todos</MenuItem>
              <MenuItem value="PROFISSIONAL1">Profissional 1</MenuItem>
              <MenuItem value="PROFISSIONAL2">Profissional 2</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        {/* <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            flexWrap="wrap"
          >
            <Typography fontWeight="bold">Data do Resultado:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={periodDtResultado}
                onChange={handleSetPeriodDtResultado}
                row
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {periodDtResultado === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      handleSetFilter(
                        moment(date).format('DD/MM/YYYY'),
                        'data_ini_resultado'
                      );
                      setDataResultadoIni(moment(date).format('DD-MM-YYYY'));
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoIni
                        ? moment(dtResultadoIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      handleSetFilter(
                        moment(date).format('DD/MM/YYYY'),
                        'data_fin_resultado'
                      );
                      setDataResultadoFinal(moment(date).format('DD-MM-YYYY'));
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoFinal
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {periodDtResultado === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      setDataResultadoFinal(moment(date).format('DD-MM-YYYY'));
                      setDataResultadoIni(moment(date).format('DD-MM-YYYY'));

                      handleSetFilter(
                        moment(date).format('DD-MM-YYYY'),
                        'dt_fin_coleta'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoFinal
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid> */}

        <Grid item xs={12}>
          <Box display="flex" gap={2} my={2}>
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    disabled={disableOtherInputs}
                    label="Pesquisar"
                    size="small"
                    error={!!errors?.search}
                    helperText={errors?.search?.message}
                    value={value ? value : ''}
                    onChange={(e) => {
                      clearErrors('search');
                      onChange(e.target.value);
                      handleSetFilter(e.target.value, 'search');
                    }}
                    placeholder="Pesquise por Nome, Nº do Cartão SUS ou CPF da paciente"
                    sx={{
                      '.MuiOutlinedInput-root': {
                        paddingRight: '9px',
                      },
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <Button
                          type="button"
                          onClick={() => {
                            validationSubmit({
                              fnWhenFormIsValid,
                              estadosSelected,
                              municipiosSelected,
                              estabelecimentosSelected,
                              setErrorsFields,
                              setDisableOtherInputs,
                              watch,
                              setError,
                              clearErrors,
                            });
                          }}
                        >
                          <SearchIcon
                            sx={{ cursor: 'pointer', color: 'gray' }}
                          />
                        </Button>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />

            <Button
              sx={{ padding: '6px 22px' }}
              variant="contained"
              type="submit"
            >
              Filtrar
            </Button>

            <Button
              sx={{ padding: '6px 22px' }}
              variant="outlined"
              color="error"
              onClick={clearValues}
            >
              Limpar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsColposcopia;
