import { watch } from 'fs';
import api from '../../../api';
import { getUserToken } from '../../../lib/auth';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import useStatesFields from './useStatesFields';
import { validarCPF } from '../../../utils/validateCPF';
import { validarCNS } from '../../../utils/verifyCns';

const useActions = () => {
  const userToken = getUserToken();
  const isPadrao = userToken?.permissao_atual?.id === 1;

  const isMunicipial = userToken?.permissao_atual?.id === 3;

  const userIsPadrao =
    userToken.permissao_atual.id === 1 ||
    userToken.permissao_atual.id === 6 ||
    userToken.permissao_atual.id === 7 ||
    userToken.permissao_atual.id === 8 ||
    userToken.permissao_atual.id === 9 ||
    userToken.permissao_atual.id === 10;

  const userIsLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO';

  const actionsEstadoSelected = async ({
    estadosSelected,
    setOptionsMunicipios,
    setAllMunicipiosOptions,
    handleSetFiltersInMultiplesInputs,
    setDisabledMunicipioInput,
    handleSetFilter,
    setEstabelecimentosSelected,
    setMunicipiosSelected,
    setEquipesSelected,
    setDisabledEstabelecimentoInput,
    setDisableEquipeInput,
    setLoadingMunicipios,
    setErrorsFields,
    errorsFields,
  }) => {
    if (userIsPadrao && !userIsLaboratorio) {
      const optionCidade = [
        {
          id: userToken.endereco.municipio_id,
          nome: userToken.endereco.municipio_nome,
          codigo: userToken.endereco.municipio_code,
          estadoSigla: userToken.endereco.estado,
        },
      ];
      setOptionsMunicipios(optionCidade);
    } else {
      const pathname = window.location.pathname;
      const isResults =
        pathname === '/relatorios/histopatologia' ||
        pathname === '/relatorios/colposcopia' ||
        pathname === '/relatorios/citologia-reflexo' ||
        pathname === '/relatorios/lesoes-intraepiteliais';

      if (estadosSelected.length === 1) {
        if (isResults) {
          setDisabledMunicipioInput(false);
        } else {
          setDisabledMunicipioInput(
            isPadrao || isMunicipial || userToken.permissao_atual.id === 6
              ? true
              : false
          );
        }

        setLoadingMunicipios(true);
        const optionsCidades = await getCidades(estadosSelected[0]);

        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptions(optionsCidades);
        setLoadingMunicipios(false);
        setErrorsFields(errorsFields.filter((field) => field !== 'uf'));
      }

      if (estadosSelected.length === 0 && !isPadrao && !isMunicipial) {
        setOptionsMunicipios([]);
      }

      if (estadosSelected.length > 0) {
        handleSetFiltersInMultiplesInputs({
          value: estadosSelected[0] ?? '',
          fieldName: 'estado',
          arrayValues: estadosSelected,
        });
        setErrorsFields(errorsFields.filter((field) => field !== 'uf'));
      } else {
        if (!isResults) {
          setDisabledMunicipioInput(true);
        }
        setDisabledEstabelecimentoInput(true);
        setDisableEquipeInput(true);
        handleSetFilter(null, 'estado', 'linha 50 useActions');
      }

      if (estadosSelected.length > 1 || estadosSelected.length === 0) {
        if (!isPadrao) {
          setEstabelecimentosSelected([]);
        }
        setMunicipiosSelected([]);
        setEquipesSelected([]);

        if (!isResults) {
          setDisabledMunicipioInput(true);
        }
        setDisabledEstabelecimentoInput(true);
        setDisableEquipeInput(true);
      } else {
        if (isResults) {
          setDisabledMunicipioInput(false);
          return;
        }
        setDisabledMunicipioInput(
          isPadrao || isMunicipial || userToken.permissao_atual.id === 6
            ? true
            : false
        );
      }
    }
  };

  const pathname = window.location.pathname;

  const isResults =
    pathname === '/relatorios/citologia-reflexo' ||
    pathname === '/relatorios/histopatologia' ||
    pathname === '/relatorios/colposcopia' ||
    pathname === '/relatorios/hpv';

  const actionsMunicipioSelected = async ({
    municipiosSelected,
    handleSetFiltersInMultiplesInputs,
    setDisabledEstabelecimentoInput,
    setDisableEquipeInput,
    handleSetFilter,
    estadosSelected,
    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setEstabelecimentosSelected,
    setEquipesSelected,
    optionsMunicipios,
    setLoadingEstabelecimentos,
    setErrorsFields,
    errorsFields,
    laboratorioField,
    setDisableOtherInputs,
    clearValuesInputs,
    routeGetEstabelecimentos,
    setOptionsLaboratorios,
    setOptionsServicos,
    setAllServicosOptions,
    setAllLaboratoriosOptions,
  }: Partial<any>) => {
    if (userIsPadrao && !userIsLaboratorio) {
      setOptionsEstabelecimentos([
        {
          id: userToken.estabelecimentoId,
          municipio: userToken.endereco.municipio_nome,
          municipio_id: userToken.endereco.municipio_id,
          nome: userToken.estabelecimento_saude.nome_fantasia,
          label: userToken.estabelecimento_saude.nome_fantasia,
        },
      ]);
    } else {
      const page = {
        'citologia-reflexo': 'citologia',
        histopatologia: 'histopatologia',
        colposcopia: 'colposcopia',
        hpv: 'hpv',
      };

      let arrayMunicipiosNomes: any = [];

      municipiosSelected.forEach((idMunicipio) => {
        const municipioNome = optionsMunicipios?.find(
          (municipio) => String(municipio.id) === String(idMunicipio)
        )?.nome;

        arrayMunicipiosNomes.push(idMunicipio);
      });

      const municipioSelected =
        optionsMunicipios?.length > 0
          ? optionsMunicipios?.find(
              (municipio) =>
                String(municipio.id) === String(municipiosSelected[0])
            )
          : undefined;

      if (municipiosSelected.length > 0) {
        setErrorsFields(errorsFields.filter((field) => field !== 'municipio'));

        if (pathname.split('/')[2] === 'lesoes-intraepiteliais') {
          const optionsServicos = await api.get(
            `/dashboard/exames/intraepitelial-lieag/panel/servicos-saude`,
            {
              params: {
                estados:
                  estadosSelected.length > 0
                    ? JSON.stringify(estadosSelected)
                    : undefined,
                municipios:
                  municipiosSelected.length > 0
                    ? arrayMunicipiosNomes.join(', ')
                    : undefined,
              },
            }
          );

          setOptionsServicos(
            optionsServicos.data.map((optLab) => {
              return {
                label: optLab.label,
                value: optLab.id,
              };
            })
          );
          setAllServicosOptions(
            optionsServicos.data.map((optLab) => {
              return {
                label: optLab.label,
                value: optLab.id,
              };
            })
          );
        }
        if (isResults) {
          const optionsLaboratorios = await api.get(
            `/dashboard/exames/${
              page[pathname.split('/')[2]]
            }/panel/laboratorios`,
            {
              params: {
                estados:
                  estadosSelected.length > 0
                    ? JSON.stringify(estadosSelected)
                    : undefined,
                municipios:
                  municipiosSelected.length > 0
                    ? arrayMunicipiosNomes.join(', ')
                    : undefined,
              },
            }
          );
          setOptionsLaboratorios(
            optionsLaboratorios.data.map((optLab) => {
              return {
                label: optLab.label,
                value: optLab.id,
              };
            })
          );
          setAllLaboratoriosOptions(
            optionsLaboratorios.data.map((optLab) => {
              return {
                label: optLab.label,
                value: optLab.id,
              };
            })
          );
        }
      } else {
        if (!isResults) {
          setDisabledEstabelecimentoInput(true);
        }
        setDisableEquipeInput(true);
        handleSetFilter(null, 'municipio');
      }

      if (municipiosSelected.length > 1 || municipiosSelected.length === 0) {
        if (!isPadrao) {
          setEstabelecimentosSelected([]);
        }

        if (!isResults) {
          setDisabledEstabelecimentoInput(true);
        }
        setDisabledEstabelecimentoInput(true);
        setEquipesSelected([]);
        setDisableEquipeInput(true);
      } else {
      }

      if (!municipioSelected) {
        if (isResults) {
          setDisableOtherInputs(true);
          clearValuesInputs();
        }
      }

      if (municipiosSelected.length === 1) {
        setErrorsFields(errorsFields.filter((field) => field !== 'municipio'));
        if (isPadrao) {
          setDisabledEstabelecimentoInput(true);
        } else {
          setDisabledEstabelecimentoInput(false);
        }
        setLoadingEstabelecimentos(true);
        const selectedMunicipioLabel = municipioSelected?.nome;
        if (isResults) {
          setDisableOtherInputs(false);
        }

        if (selectedMunicipioLabel) {
          handleSetFiltersInMultiplesInputs({
            value: selectedMunicipioLabel ?? '',
            fieldName: 'municipio',
            arrayValues: municipiosSelected,
          });

          const pathname = window.location.pathname.split('/')[2];

          const urlEstabelecimentos = {
            'teste-hpv': '/dashboard/exames/hpv/panel/estabelecimentos',
            hpv: `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
            'citologia-reflexo': `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
            histopatologia: `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
            colposcopia: `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
            'teste-colposcopia': `/dashboard/exames/colposcopia/panel/estabelecimentos`,

            'lesoes-intraepiteliais': `/dashboard/exames/intraepitelial-lieag/panel/estabelecimentos`,
            'rastreamento-hpv': `/dashboard/rastreamento/hpv/estabelecimentos`,
            'teste-citologia-reflexo':
              '/dashboard/exames/citologia/panel/estabelecimentos',
            'teste-histopatologia': `/dashboard/exames/histopatologia/panel/estabelecimentos`,
          };

          const optionsEstabelecimentos = await api.get(
            urlEstabelecimentos[pathname],
            {
              params: {
                estados:
                  estadosSelected.length > 0
                    ? JSON.stringify(estadosSelected)
                    : undefined,
                municipios:
                  municipiosSelected.length > 0
                    ? arrayMunicipiosNomes.join(', ')
                    : undefined,
              },
            }
          );

          if (!laboratorioField) {
            setOptionsEstabelecimentos([
              {
                id: 'null',
                municipio: '',
                nome: 'SEM VINCULAÇÃO',
                quantidade: '',
                label: '',
              },
              ...optionsEstabelecimentos.data,
            ]);

            setAllEstabelecimentosOptions([
              {
                id: 'null',
                municipio: '',
                nome: 'SEM VINCULAÇÃO',
                quantidade: '',
                label: '',
              },
              ...optionsEstabelecimentos.data,
            ]);
          } else {
          }
        }

        setLoadingEstabelecimentos(false);
      }

      if (municipiosSelected.length > 1) {
        handleSetFiltersInMultiplesInputs({
          value: 'Vários',
          fieldName: 'municipio',
          arrayValues: municipiosSelected,
        });
      }

      if (municipiosSelected.length === 0) {
        setOptionsEstabelecimentos([]);
      }
    }
  };

  // const actionsMunicipioSelected = async ({
  //   municipiosSelected,
  //   handleSetFiltersInMultiplesInputs,
  //   setDisabledEstabelecimentoInput,
  //   setDisableEquipeInput,
  //   handleSetFilter,
  //   estadosSelected,
  //   setOptionsEstabelecimentos,
  //   setAllEstabelecimentosOptions,
  //   setEstabelecimentosSelected,
  //   setEquipesSelected,
  //   optionsMunicipios,
  //   setLoadingEstabelecimentos,
  //   setErrorsFields,
  //   errorsFields,
  //   laboratorioField,
  //   setDisableOtherInputs,
  //   clearValuesInputs,
  //   routeGetEstabelecimentos,
  //   setOptionsLaboratorios,

  //   setAllLaboratoriosOptions,
  // }: Partial<any>) => {
  //   let arrayMunicipiosNomes: any = [];

  //   municipiosSelected.forEach((idMunicipio) => {
  //     const municipioNome = optionsMunicipios?.find(
  //       (municipio) => String(municipio.id) === String(idMunicipio)
  //     )?.nome;

  //     arrayMunicipiosNomes.push(idMunicipio);
  //   });

  //   const municipioSelected =
  //     optionsMunicipios?.length > 0
  //       ? optionsMunicipios?.find(
  //           (municipio) =>
  //             String(municipio.id) === String(municipiosSelected[0])
  //         )
  //       : undefined;

  //   if (municipiosSelected.length > 0) {
  //     setErrorsFields(errorsFields.filter((field) => field !== 'municipio'));
  //   } else {
  //     if (!isResults) {
  //       setDisabledEstabelecimentoInput(true);
  //     }
  //     setDisableEquipeInput(true);
  //     handleSetFilter(null, 'municipio');
  //   }

  //   if (municipiosSelected.length > 1 || municipiosSelected.length === 0) {
  //     if (!isPadrao) {
  //       setEstabelecimentosSelected([]);
  //     }

  //     if (!isResults) {
  //       setDisabledEstabelecimentoInput(true);
  //     }
  //     setDisabledEstabelecimentoInput(true);
  //     setEquipesSelected([]);
  //     setDisableEquipeInput(true);
  //   } else {
  //   }

  //   if (!municipioSelected) {
  //     if (isResults) {
  //       setDisableOtherInputs(true);
  //       clearValuesInputs();
  //     }
  //   }

  //   if (municipiosSelected.length === 1) {
  //     setErrorsFields(errorsFields.filter((field) => field !== 'municipio'));
  //     setDisabledEstabelecimentoInput(isPadrao ? true : false);
  //     setLoadingEstabelecimentos(true);
  //     const selectedMunicipioLabel = municipioSelected?.nome;
  //     if (isResults) {
  //       setDisableOtherInputs(false);
  //     }

  //     if (selectedMunicipioLabel) {
  //       handleSetFiltersInMultiplesInputs({
  //         value: selectedMunicipioLabel ?? '',
  //         fieldName: 'municipio',
  //         arrayValues: municipiosSelected,
  //       });

  //       const pathname = window.location.pathname.split('/')[2];

  //       const page = {
  //         'citologia-reflexo': 'citologia',
  //         histopatologia: 'histopatologia',
  //         colposcopia: 'colposcopia',
  //         hpv: 'hpv',
  //       };

  //       const urlEstabelecimentos = {
  //         hpv: `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
  //         'citologia-reflexo': `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
  //         histopatologia: `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
  //         colposcopia: `/dashboard/exames/${page[pathname]}/panel/estabelecimentos`,
  //         'lesoes-intraepiteliais': `/dashboard/exames/intraepitelial-lieag/panel/estabelecimentos`,
  //       };

  //       const optionsEstabelecimentos = await api.get(
  //         urlEstabelecimentos[pathname],
  //         {
  //           params: {
  //             estados:
  //               estadosSelected.length > 0
  //                 ? JSON.stringify(estadosSelected)
  //                 : undefined,
  //             municipios:
  //               municipiosSelected.length > 0
  //                 ? arrayMunicipiosNomes.join(', ')
  //                 : undefined,
  //           },
  //         }
  //       );
  //       const optionsLaboratorios = await api.get(
  //         `/dashboard/exames/${page[pathname]}/panel/laboratorios`,
  //         {
  //           params: {
  //             estados:
  //               estadosSelected.length > 0
  //                 ? JSON.stringify(estadosSelected)
  //                 : undefined,
  //             municipios:
  //               municipiosSelected.length > 0
  //                 ? arrayMunicipiosNomes.join(', ')
  //                 : undefined,
  //           },
  //         }
  //       );

  //       // const optionsEstabelecimentos = await api.get(
  //       //   laboratorioField
  //       //     ? `/dashboard/exames/${page[pathname]}/panel/laboratorios`
  //       //     : routeGetEstabelecimentos,
  //       //   {
  //       //     params: {
  //       //       estados:
  //       //         estadosSelected.length > 0
  //       //           ? JSON.stringify(estadosSelected)
  //       //           : undefined,
  //       //       municipios:
  //       //         municipiosSelected.length > 0
  //       //           ? arrayMunicipiosNomes.join(', ')
  //       //           : undefined,
  //       //     },
  //       //   }
  //       // );

  //       if (!laboratorioField) {
  //         setOptionsEstabelecimentos([
  //           {
  //             id: 'null',
  //             municipio: '',
  //             nome: 'SEM VINCULAÇÃO',
  //             quantidade: '',
  //             label: '',
  //           },
  //           ...optionsEstabelecimentos.data,
  //         ]);

  //         setAllEstabelecimentosOptions([
  //           {
  //             id: 'null',
  //             municipio: '',
  //             nome: 'SEM VINCULAÇÃO',
  //             quantidade: '',
  //             label: '',
  //           },
  //           ...optionsEstabelecimentos.data,
  //         ]);
  //       } else {
  //       }

  //       if (isResults) {
  //         setOptionsLaboratorios([...optionsLaboratorios.data]);
  //         setAllLaboratoriosOptions([...optionsLaboratorios.data]);
  //       }
  //     }

  //     setLoadingEstabelecimentos(false);
  //   }

  //   if (municipiosSelected.length > 1) {
  //     handleSetFiltersInMultiplesInputs({
  //       value: 'Vários',
  //       fieldName: 'municipio',
  //       arrayValues: municipiosSelected,
  //     });
  //   }

  //   if (municipiosSelected.length === 0) {
  //     setOptionsEstabelecimentos([]);
  //   }
  // };

  const actionsEstabelecimentoSelected = async ({
    optionsEstabelecimentos,
    estabelecimentosSelected,
    handleSetFiltersInMultiplesInputs,
    handleSetFilter,
    setDisableEquipeInput,
    setDisableAcsInput,
    setArrayEquipes,
    setAllEquipesOptions,
    setAcsSelected,
    setEquipesSelected,
    setArrayAcs,
    setAllAcsOptions,
    fieldEquipe,
    setLoadingEquipes,
    setLoadingAcs,
    setErrorsFields,
    errorsFields,
  }) => {
    const estabelecimentoNome =
      optionsEstabelecimentos?.find(
        (item) => String(estabelecimentosSelected[0]) === String(item.id)
      )?.nome ?? '';

    if (estabelecimentosSelected.length > 0) {
      setErrorsFields(
        errorsFields.filter((field) => field !== 'estabelecimento')
      );
      if (estabelecimentoNome) {
        handleSetFiltersInMultiplesInputs({
          value: estabelecimentoNome,
          fieldName: 'estabelecimento_saude',
          arrayValues: estabelecimentosSelected,
        });
      }
    } else {
      handleSetFilter(null, 'estabelecimento_saude');
    }
    const hasSelectedNoBind = !!estabelecimentosSelected.find(
      (item) => item === 'null'
    );

    if (
      estabelecimentosSelected.length > 1 ||
      estabelecimentosSelected.length === 0
    ) {
      setEquipesSelected([]);
      setAcsSelected([]);
      setDisableAcsInput(true);
      setDisableEquipeInput(true);
    } else {
      setDisableAcsInput(false);
      setDisableEquipeInput(false);
    }

    if (hasSelectedNoBind) {
      setDisableAcsInput(true);
      setDisableEquipeInput(true);
    }

    if (estabelecimentosSelected.length === 1) {
      setErrorsFields(
        errorsFields.filter((field) => field !== 'estabelecimento')
      );
      setLoadingEquipes(true);
      setLoadingAcs(true);
      setDisableAcsInput(false);
      setDisableEquipeInput(false);

      if (!hasSelectedNoBind) {
        if (fieldEquipe) {
          const response = await api.get('/equipe/estabelecimento/get', {
            params: {
              estabelecimentoId: estabelecimentosSelected[0],
            },
          });

          setArrayEquipes([
            {
              id: 'null',
              ine: 'SEM VINCULAÇÃO',
            },
            ...response.data,
          ]);
          setAllEquipesOptions([
            {
              id: 'null',
              ine: 'SEM VINCULAÇÃO',
            },

            ...response.data,
          ]);
        } else {
          const pathname = window.location.pathname;

          const isResults =
            pathname === '/relatorios/citologia-reflexo' ||
            pathname === '/relatorios/histopatologia' ||
            pathname === '/relatorios/colposcopia';

          if (!isResults) {
            const response = await api.get('/dashboard/rastreamento/hpv/acs', {
              params: {
                estabelecimento_id: estabelecimentosSelected[0],
              },
            });

            let arrFormated: any = [];
            response?.data?.forEach((acs) => {
              const formatedAcsObj = {
                id: acs.profissional_id,
                pessoa: {
                  nome: acs.nome,
                },
              };

              if (formatedAcsObj.id !== 0) {
                arrFormated.push(formatedAcsObj);
              }
            });

            setArrayAcs([
              {
                id: 'null',
                pessoa: {
                  nome: 'SEM VINCULAÇÃO',
                },
              },
              ...arrFormated,
            ]);
            setAllAcsOptions([
              {
                id: 'null',
                pessoa: {
                  nome: 'SEM VINCULAÇÃO',
                },
              },
              ...arrFormated,
            ]);
          }
        }
      }

      setLoadingEquipes(false);
      setLoadingAcs(false);
    }

    if (estabelecimentosSelected.length === 0) {
      setArrayEquipes([]);
    }
  };

  const actionsEquipeSelected = async ({
    equipesSelected,
    handleSetFiltersInMultiplesInputs,
    arrayEquipes,
    handleSetFilter,
  }) => {
    if (equipesSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value:
          arrayEquipes.find((item) => item?.id === equipesSelected[0])?.ine ??
          '',
        fieldName: 'equipe',
        arrayValues: equipesSelected,
      });
    } else {
      handleSetFilter(null, 'equipe');
    }
  };

  const actionsAcsSelected = async ({
    acsSelected,
    handleSetFiltersInMultiplesInputs,
    arrayAcs,
    handleSetFilter,
  }) => {
    if (acsSelected.length > 0) {
      const acsName = arrayAcs.find((item) => item?.id === acsSelected[0])
        ?.pessoa?.nome;

      if (acsName) {
        handleSetFiltersInMultiplesInputs({
          value: acsName ?? '',
          fieldName: 'acs',
          arrayValues: acsSelected,
        });
      }
    } else {
      handleSetFilter(null, 'acs');
    }
  };

  const validationSubmit = ({
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    fnWhenFormIsValid,
    setErrorsFields,
    setDisableOtherInputs,
    watch,
    setError,
    clearErrors,
  }) => {
    let inputSearchIsValid: any;
    let inputRealizouAtendimentoIsValid: any;

    const secondValidation = () => {
      const verifyingIfAllCharactersInputSearchIsNumber = (str) => {
        const isAllNumbers = /^[0-9]+$/.test(str);
        return isAllNumbers;
      };

      const inputValueWithoutEspecialChar = watch('search')?.replace(
        /[^a-zA-Z0-9]/g,
        ''
      );
      if (
        inputValueWithoutEspecialChar?.length < 11 &&
        verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        )
      ) {
        setError('search', {
          type: 'manual',
          message:
            'Número de dígitos incompletos, digite 11 números para CPF ou 15 para CNS',
        });

        inputSearchIsValid = false;
      } else if (
        inputValueWithoutEspecialChar?.length === 11 &&
        verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        ) &&
        validarCPF(inputValueWithoutEspecialChar)
      ) {
        inputSearchIsValid = true;
        clearErrors('search');
      } else if (
        inputValueWithoutEspecialChar?.length === 11 &&
        verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        ) &&
        !validarCPF(inputValueWithoutEspecialChar)
      ) {
        setError('search', {
          type: 'manual',
          message: 'Digite um CPF válido.',
        });
      } else if (
        inputValueWithoutEspecialChar?.length > 11 &&
        inputValueWithoutEspecialChar?.length < 15 &&
        verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        )
      ) {
        setError('search', {
          type: 'manual',
          message:
            'Número de dígitos incompletos, digite 11 números para CPF ou 15 para CNS',
        });
      } else if (
        inputValueWithoutEspecialChar?.length === 15 &&
        verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        )
      ) {
        inputSearchIsValid = true;
        clearErrors('search');
      } else if (
        inputValueWithoutEspecialChar > 15 &&
        verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        )
      ) {
        setError('search', {
          type: 'manual',
          message:
            'Número de dígitos excedentes, digite 11 números para CPF ou 15 para CNS',
        });
      } else if (
        !verifyingIfAllCharactersInputSearchIsNumber(
          inputValueWithoutEspecialChar
        )
      ) {
        inputSearchIsValid = true;
        clearErrors('search');
      }
      if (
        watch('realizou_atendimento') === 'Não' &&
        !!watch('atendimento_status') === false
      ) {
        setError('atendimento_status', {
          type: 'manual',
          message: 'Campo obrigatório',
        });

        inputRealizouAtendimentoIsValid = false;
      } else {
        inputRealizouAtendimentoIsValid = true;
      }

      if (
        inputRealizouAtendimentoIsValid === true &&
        inputSearchIsValid === true
      ) {
        fnWhenFormIsValid();
      }
    };
    const hasEstadoSelected = estadosSelected.length > 0;
    const hasMunicipioSelected = municipiosSelected.length > 0;
    const hasEstabelecimentoSelected = estabelecimentosSelected.length > 0;

    const verifyingIfSomeFieldIsMoreThanOneValueSelected = () => {
      const estadoHasMoreThanOneValue = estadosSelected.length > 1;
      const municipioHasMoreThanOneValue = municipiosSelected.length > 1;
      const estabelecimentoHasMoreThanOneValue =
        estabelecimentosSelected.length > 1;
      return [
        estadoHasMoreThanOneValue,
        municipioHasMoreThanOneValue,
        estabelecimentoHasMoreThanOneValue,
      ];
    };

    const hasSomeFieldWithMoreThanOneValue =
      !!verifyingIfSomeFieldIsMoreThanOneValueSelected()?.find(
        (has) => has === true
      );
    if (
      (hasEstadoSelected &&
        hasMunicipioSelected &&
        hasEstabelecimentoSelected) ||
      hasSomeFieldWithMoreThanOneValue
    ) {
      setErrorsFields([]);
      secondValidation();

      setDisableOtherInputs(false);
    } else {
      if (isResults && hasEstadoSelected && hasMunicipioSelected) {
        setErrorsFields([]);
        secondValidation();
        setDisableOtherInputs(false);
      }

      const fields = isResults
        ? {
            uf: estadosSelected,
            municipio: municipiosSelected,
          }
        : {
            uf: estadosSelected,
            municipio: municipiosSelected,
            estabelecimento: estabelecimentosSelected,
          };

      let fieldsWithNoValues: any = [];

      Object.keys(fields).forEach((field) => {
        const value = fields[field];

        if (value.length === 0) {
          fieldsWithNoValues.push(field);
        }
      });
      setErrorsFields(fieldsWithNoValues);
    }
  };

  return {
    actionsEstadoSelected,
    actionsEstabelecimentoSelected,
    actionsMunicipioSelected,
    actionsEquipeSelected,
    actionsAcsSelected,
    validationSubmit,
  };
};

export default useActions;
