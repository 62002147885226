import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsCitologiaReflexo,

  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setDataColetaIni,
  setDataColetaFinal,
  setSelectedResultado,
  setSelectedOptions,
  setPayload,
  setSelectedLaboratorio,
  setSelectedOptionsLaboratorio,
  setListFilterShow,
}) => {
  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const clearValues = async () => {
    setErrorsFields([]);
    setTableData([]);

    setDataColetaIni(null);
    setDataColetaFinal(null);
    setDataColetaFinal(null);
    setDataColetaIni(null);
    setSelectedResultado([]);
    setSelectedOptions([]);

    const fieldsToClearWhenIsPadrao: any[] = [
      'resultado',
      'profissionalLaudo',
      'data_ini',
      'data_fin',
      'search',
    ];

    const fieldsToClearWhenIsPadraoLaboratorio: any[] = [
      'resultado',
      'estabelecimento_saude',
      'data_ini',
      'data_fin',
      'search',
    ];

    const fieldsToClearWhenSuperAdmin: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estado',
      'municipio',
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenIsMunicipal: any[] = [
      ...fieldsToClearWhenIsPadrao,

      'estabelecimento_saude',
    ];

    const fieldsToClearWhenEstadual = [
      'municipio',
      ...fieldsToClearWhenIsMunicipal,
    ];

    const permissaoId = getUserToken().permissao_atual.id;
    const handleSetFilterToShow = (newValue: any, inputName: string) => {
      setListFilterShow((prev) =>
        prev.map((item) =>
          item.inputName === inputName ? { ...item, value: newValue } : item
        )
      );
    };
    const isPadrao =
      permissaoId === 1 ||
      permissaoId === 9 ||
      permissaoId === 10 ||
      permissaoId === 7;

    const isLaboratorio =
      getUserToken().permissao_atual.contexto === 'LABORATÓRIO';

    const isMunicipal = permissaoId === 3;
    const isEstadual = permissaoId === 4;

    if (isMunicipal) {
      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setValue('profissionalResultadoId', null);

      setEstabelecimentosSelected([]);
      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);
      await setPayload().then((payload) => {
        try {
          loadReportsCitologiaReflexo({
            estado: payload.estado,
            municipioId: payload.municipioId,
            tipo: 'Reflexo',
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isPadrao && !isLaboratorio) {
      setValue('profissionalResultadoId', null);

      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsCitologiaReflexo({
            estado: payload.estado,
            tipo: 'Reflexo',

            municipioId: payload.municipioId,
            estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
            // laboratorioLaudoId: payload.laboratorioLaudoId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isPadrao && isLaboratorio) {
      fieldsToClearWhenIsPadraoLaboratorio.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
        setMunicipiosSelected([]);
        setEstabelecimentosSelected([]);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsCitologiaReflexo({
            estados: payload.estado,
            tipo: 'Reflexo',
            profissionalResultadoId: payload.profissionalResultadoId,
            laboratorioLaudoId: payload.laboratorioLaudoId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (permissao === 'isSuperAdmin') {
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setValue('profissionalResultadoId', null);

      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (isEstadual) {
      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setValue('profissionalResultadoId', null);

      setShowTableData(false);
      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;
