import Alert from '@mui/material/Alert';

const EmptyData = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          height: '48px',
          backgroundColor: '#e5f6fd',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '14px',
          borderRadius: '4px',
          marginTop: '85px',
          padding: '0 16px 0 16px',
        }}
      >
        <Alert severity="info" sx={{ padding: '0' }} />
        Não há dados disponíveis
      </div>
    </div>
  );
};

export default EmptyData;
