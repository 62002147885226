import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsHpv,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterToShow,
  setDataColetaIni,
  setDataColetaFinal,
  setDataResultadoFinal,
  setDataResultadoIni,
}) => {
  const fieldsToClearWhenIsPadrao: any[] = [
    'resultado',
    'profissionalColetaId',
    'equipe',
    'acs',
    'motivo',
    'idade_ini',
    'idade_fin',
    'atendimento_status',
    'inspecao_colo',
    'responsavel_coleta',
    'dt_coleta',
    'estabelecimentoLaudoId',
    'dt_resultado',
    'search',
    'realizou_atendimento',
    'natureza',
    'tipo_virus',
    'dt_coleta_inicio',
    'dt_coleta_fim',
    'dt_resultado_inicio',
    'dt_resultado_fim',
  ];

  const fieldsToClearWhenIsMunicipal = [
    'estabelecimento_saude',
    ...fieldsToClearWhenIsPadrao,
  ];

  const fieldsToClearWhenEstadual = [
    'municipio',
    ...fieldsToClearWhenIsMunicipal,
  ];

  const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterToShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const permissaoId = getUserToken().permissao_atual.id;

  const isPadrao =
    permissaoId === 1 ||
    permissaoId === 9 ||
    permissaoId === 10 ||
    permissaoId === 7;

  const isMunicipal = permissaoId === 3;
  const isEstadual = permissaoId === 4;

  const clearValues = () => {
    setErrorsFields([]);
    setTableData([]);
    setShowTableData(false);
    setDataColetaIni(null);
    setDataColetaFinal(null);
    setDataResultadoFinal(null);
    setDataResultadoIni(null);
    if (isPadrao) {
      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   municipio: currentEstablishment.endereco.municipio.nome,
      //   estabelecimentoId: currentEstablishment.id,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (isMunicipal) {
      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });

      setEstabelecimentosSelected([]);

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   municipio: currentEstablishment.endereco.municipio.nome,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (isEstadual) {
      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });

      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isSuperAdmin') {
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;
