import { ApexOptions } from 'apexcharts';
import { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { DataSource } from '../dataSource';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import EmptyData from '../../../../../components/Charts/emptyData';

interface AreaChartProps {
  data: number[];
  categories: String[];
  height?: number;
  payload: any;
  total?: number;
}

export function AreaChart({
  data,
  categories,
  height,
  payload,
  total,
}: AreaChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  const navigate = useNavigate();
  const emptyData = !data.find((item) => item > 0);

  const handleBarClick = (event, chartContext, config) => {
    // const clickedCategoryIndex = config.dataPointIndex;
    // const clickedCategoryName = categories[clickedCategoryIndex];
    // if (clickedCategoryName) {
    //   localStorage.removeItem('result');
    //   localStorage.removeItem('typeVirus');
    //   localStorage.removeItem('reasonNotProcedure');
    //   localStorage.removeItem('collectionOrigin');
    //   const currentDate = new Date();
    //   const currentYear = currentDate.getFullYear();
    //   const isPastMonth = (selectedMonth) => {
    //     const currentMonth = currentDate.getMonth();
    //     const months = [
    //       'JAN',
    //       'FEV',
    //       'MAR',
    //       'ABR',
    //       'MAI',
    //       'JUN',
    //       'JUL',
    //       'AGO',
    //       'SET',
    //       'OUT',
    //       'NOV',
    //       'DEZ',
    //     ];
    //     const selectedMonthIndex = months.findIndex(
    //       (month) => month === selectedMonth.toUpperCase()
    //     );
    //     if (currentMonth >= selectedMonthIndex) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   };
    //   if (isPastMonth(clickedCategoryName)) {
    //     navigate('/relatorios/hpv', {
    //       state: {
    //         origin: 'tables',
    //         payload: payload,
    //         panelClicked: 'totalTests',
    //         clickedCategory: clickedCategoryName,
    //         year: currentYear,
    //       },
    //     });
    //   } else {
    //     navigate('/relatorios/hpv', {
    //       state: {
    //         origin: 'tables',
    //         payload: payload,
    //         panelClicked: 'totalTests',
    //         clickedCategory: clickedCategoryName,
    //         year: currentYear - 1,
    //       },
    //     });
    //   }
    // }
  };

  useEffect(() => {
    // if (data && categories.length > 0) {
    setChartData({
      series: [
        {
          name: 'Testes Realizados',
          data,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      chart: {
        height: 700,
        type: 'area',
        events: {
          click: handleBarClick,
          // toolbarButtonClicked: (event, chartContext, config) => {
          //   // Identificar qual ferramenta foi clicada
          //   const toolName = config.tool;
          //   handleToolbarClick(toolName);
          // },
        },

        toolbar: {
          show: true,
          tools: {
            download: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            reset: true,
          },
        },
        sparkline: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories,
        labels: {
          style: {
            fontSize: '12px',
          },

          minHeight: 40,
        },
      },
      colors: ['#76AB78'],
      grid: {
        show: true,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        labels: {
          show: true,
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        type: 'solid',
        opacity: 0.4,
      },
      // responsive: [
      //   {
      //     breakpoint: 768,
      //     options: {
      //       xaxis: {
      //         labels: {
      //           // minHeight: '60px',
      //           style: {
      //             fontSize: '5px',
      //           },
      //         },
      //       },
      //     },
      //   },
      // ],
    });
    // }
  }, [categories, data, height]);

  return (
    <>
      {chartData && !!categories.length ? (
        <div
          style={{
            padding: '0 16px',
            display: 'flex',

            flexDirection: 'column',

            position: 'relative',
          }}
        >
          {emptyData && (
            <div style={{ position: 'absolute', left: '40%', zIndex: '1' }}>
              <EmptyData />
            </div>
          )}
          <p style={{ display: 'none' }} data-testid="totalTestsRealized">
            {total?.toLocaleString('pt-BR')}
          </p>
          <Chart
            options={chartData}
            series={chartData?.series}
            type={chartData?.chart?.type || 'area'}
            height={310}
          />

          {/* <>
            <div className={styles.monthsContainer}>
              {categories.map((category, index) => (
                <span key={index}>{category} </span>
              ))}
            </div>
          </> */}

          {/* <DataSource /> */}
        </div>
      ) : undefined}
    </>
  );
}
