import { Button, Checkbox, Menu, MenuItem, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useStatesFields from '../../hooks/useStatesFields';
import useHandleSelect from '../../hooks/useHandleSelect';
import { StyledAsteriskTextField } from '../../../StyledAsteriskTextField/styles';
import { getUserToken } from '../../../../lib/auth';

const ModalEstabelecimento = ({
  optionsEstabelecimentos,
  allEstabelecimentosOptions,
  searchEstabelecimentos,
  anchorElEstabelecimentos,
  estabelecimentosSelected,
  disableEstabelecimentoInput,
  setOptionsEstabelecimentos,
  setAllEstabelecimentosOptions,
  setSearchEstabelecimentos,
  setAnchorEstabelecimentos,
  setEstabelecimentosSelected,
  loadingEstabelecimentos,
  errors,
  disableRequiredEstabelecimento,
}) => {
  const { handleSelectMultiplesFields } = useHandleSelect();

  const hasError = !!errors.find((error) => error === 'estabelecimento');

  const userToken = getUserToken();

  const userIsPadrao =
    userToken.permissao_atual.id === 1 ||
    userToken.permissao_atual.id === 6 ||
    userToken.permissao_atual.id === 7 ||
    userToken.permissao_atual.id === 8 ||
    userToken.permissao_atual.id === 9 ||
    userToken.permissao_atual.id === 10;

  const isLaboratorio = userToken.permissao_atual.contexto === 'LABORATÓRIO';

  return (
    <>
      <StyledAsteriskTextField
        required={disableRequiredEstabelecimento}
        size="small"
        data-testid="modalEstabelecimentoInput"
        error={hasError}
        helperText={hasError ? 'Campo Obrigatório.' : undefined}
        disabled={
          userIsPadrao && !isLaboratorio ? true : disableEstabelecimentoInput
        }
        label="Estabelecimento de Saúde"
        value={
          estabelecimentosSelected.length > 1
            ? 'Vários'
            : optionsEstabelecimentos.find(
                (item) => item.id === estabelecimentosSelected[0]
              )?.nome ?? ''
        }
        sx={{
          padding: 0,
          width: '100%',
          '.MuiOutlinedInput-root': {
            paddingRight: '9px',
          },
        }}
        InputProps={{
          endAdornment: (
            <Button
              data-testid="modalEstabelecimentoDropdownButton"
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              disabled={
                userIsPadrao && !isLaboratorio
                  ? true
                  : disableEstabelecimentoInput
              }
              onClick={(event) => {
                window.scrollTo(0, 0);
                setAnchorEstabelecimentos(event.currentTarget);
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',
                  color:
                    (userIsPadrao && !isLaboratorio) ||
                    disableEstabelecimentoInput
                      ? '#BDBDBD'
                      : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />
      <Menu
        anchorEl={anchorElEstabelecimentos}
        open={!!anchorElEstabelecimentos}
        onClose={() => setAnchorEstabelecimentos(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          position: 'absolute',
          width: '100%',
          marginTop: '10px',
          left: '10px',

          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '24px 32px',
            flexDirection: 'column',
          }}
        >
          <TextField
            data-testid="inputSearchEstabelecimento"
            size="small"
            placeholder="Pesquise por Nome do Estabelecimento"
            onChange={(e) => setSearchEstabelecimentos(e.target.value)}
            value={searchEstabelecimentos}
            sx={{
              width: '289px',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            InputProps={{
              endAdornment: (
                <Button
                  data-testid="submitSearchEstabelecimento"
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                  onClick={() => {
                    if (!searchEstabelecimentos) {
                      setOptionsEstabelecimentos(allEstabelecimentosOptions);
                    } else {
                      const newArrEstabelecimentos =
                        allEstabelecimentosOptions.filter((estabelecimento) =>
                          estabelecimento.nome
                            .toLowerCase()
                            .includes(searchEstabelecimentos.toLowerCase())
                        );

                      setOptionsEstabelecimentos(newArrEstabelecimentos);
                    }
                  }}
                >
                  <SearchIcon
                    sx={{
                      cursor: 'pointer',
                      color: 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />
          <div>
            <Button
              variant="text"
              sx={{ padding: '0' }}
              data-testid="selectAllEstabelecimentos"
              onClick={() => {
                const allItemsArray = optionsEstabelecimentos.map(
                  (estabelecimento: any) => estabelecimento.id
                );
                // Incluindo 'null' se não estiver presente
                if (!allItemsArray.includes('null')) {
                  allItemsArray.push('null');
                }
                setEstabelecimentosSelected(allItemsArray);
              }}
            >
              SELECIONAR TODOS -
            </Button>
            <Button
              data-testid="clearEstabelecimentos"
              variant="text"
              sx={{ padding: '0' }}
              onClick={() => setEstabelecimentosSelected([])}
            >
              LIMPAR
            </Button>
          </div>
          {loadingEstabelecimentos ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <span
                className="loader"
                style={{ width: '40px', height: '40px' }}
              ></span>
            </div>
          ) : (
            <>
              {optionsEstabelecimentos?.map((estabelecimento: any, index) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      data-testid={`optionEstabelecimento-${index}`}
                      onChange={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setEstabelecimentosSelected,
                          state: estabelecimentosSelected,
                          value: estabelecimento,
                        });
                      }}
                      checked={
                        estabelecimentosSelected?.find(
                          (item) => item === estabelecimento.id
                        )
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setEstabelecimentosSelected,
                          state: estabelecimentosSelected,
                          value: estabelecimento,
                        });
                      }}
                    >
                      {estabelecimento?.nome}
                    </MenuItem>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default ModalEstabelecimento;
