import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomTablePagination from '../../../../../../components/PanelCustomTablePagination';

interface TableCitoColpoProps {
  data: any[];
  totals: {
    totalCitologiasAlteradas: number;
    totalColposcopiasRealizadas: number;
    totalColposcopiasARealizar: number;
  };
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  localizacaoType: string;
}

export default function TableCitoColpo({
  data,
  totals,
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowsPerPageChange,
  localizacaoType, 
}: TableCitoColpoProps) {
  const columns = [
    { id: 'nome', label: localizacaoType || 'Localidade', minWidth: 170 }, 
    {
      id: 'citologiasAlteradas',
      label: 'Mulheres com Citologia Insatisfatória ou Com Alterações Epteliais',
      minWidth: 170,
      width: 292,
    },
    {
      id: 'colposcopiasRealizadas',
      label: 'Colposcopias Realizadas',
      minWidth: 170,
    },
    {
      id: 'colposcopiasARealizar',
      label: 'Colposcopias a Realizar',
      minWidth: 170,
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <Box className="table-container" sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} className="table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>Sem Resultados</TableCell>
                </TableRow>
              ) : (
                <>
                  {data.map((row, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell>{row.citologiasAlteradas}</TableCell>
                      <TableCell>{row.colposcopiasRealizadas}</TableCell>
                      <TableCell
                        style={{
                          color: row.colposcopiasARealizar < 0 ? 'red' : 'inherit',
                        }}
                      >
                        {row.colposcopiasARealizar}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Total
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals.totalCitologiasAlteradas}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals.totalColposcopiasRealizadas}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals.totalColposcopiasARealizar}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          page={currentPage - 1}
          rowsPerPage={itemsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`}
        />
      </Paper>
    </Box>
  );
}