import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsHistopatologia,

  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterShow,
  setDataColetaIni,
  setDataColetaFinal,
  setDataResultadoFinal,
  setDataResultadoIni,
  setSelectedResultado,
  setSelectedOptionsOrigem,
  setSelectedOptionsCarater,
  setSelectedOptionsEscamosa,
  setSelectedOptionsGlandular,
  setPayload,
  setSelectedOrigem,
  setSelectedCarater,
  setSelectedEscamosa,
  setSelectedGlandular,
  setSelectedLaboratorio,
  setSelectedOptionsLaboratorio,
  setSelectedHasLesao,
}) => {
  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const clearValues = async () => {
    setErrorsFields([]);
    setTableData([]);

    setDataColetaIni(null);
    setDataResultadoFinal(null);
    setDataColetaFinal(null);
    setDataResultadoIni(null);
    setSelectedResultado();
    setSelectedOptionsOrigem([]);
    setSelectedOptionsCarater([]);
    setSelectedOptionsEscamosa([]);
    setSelectedOptionsGlandular([]);

    setSelectedOrigem([]);
    setSelectedCarater([]);
    setSelectedEscamosa([]);
    setSelectedGlandular([]);

    const fieldsToClearWhenIsPadraoLaboratorio: any[] = [
      'realizou_tratamento',
      'dt_coleta_inicio',
      'dt_coleta_fim',
      'origem',
      'lesoes_benigno',
      'lesoes_neoplasico_escamosa',
      'dt_resultado_inicio',
      'dt_resultado_fim',
      'search',
    ];

    const fieldsToClearWhenIsPadrao: any[] = [
      'realizou_tratamento',
      'dt_coleta_inicio',
      'dt_coleta_fim',
      'origem',
      'lesoes_benigno',
      'lesoes_neoplasico_escamosa',
      'dt_resultado_inicio',
      'dt_resultado_fim',
      'search',
      'laboratorio_responsavel_laudo',
      'profissional_responsavel_resultado',
    ];

    const fieldsToClearWhenSuperAdmin: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estado',
      'municipio',
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenIsMunicipal: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenEstadual = [
      'municipio',
      ...fieldsToClearWhenIsMunicipal,
    ];

    const permissaoId = getUserToken().permissao_atual.id;

    const isPadrao =
      permissaoId === 1 ||
      permissaoId === 9 ||
      permissaoId === 10 ||
      permissaoId === 7;

    const isMunicipal = permissaoId === 3;
    const isEstadual = permissaoId === 4;

    const isLaboratorio =
      getUserToken().permissao_atual.contexto === 'LABORATÓRIO';

    if (isPadrao && !isLaboratorio) {
      setValue('profissionalLaudoId', null);

      fieldsToClearWhenIsPadrao.forEach((field) => {
        setSelectedHasLesao([]);
        setSelectedOrigem([]);
        setSelectedCarater([]);
        setSelectedEscamosa([]);
        setSelectedGlandular([]);
        setSelectedLaboratorio([]);
        setSelectedOptionsLaboratorio([]);

        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsHistopatologia({
            estado: payload.estado,
            municipioId: payload.municipioId,
            estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isPadrao && isLaboratorio) {
      fieldsToClearWhenIsPadraoLaboratorio.forEach((field) => {
        setSelectedHasLesao([]);
        setSelectedOrigem([]);
        setSelectedCarater([]);
        setSelectedEscamosa([]);
        setSelectedGlandular([]);

        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsHistopatologia({
            estado: payload.estado,
            laboratorioLaudoId: payload.laboratorioLaudoId,
            profissionalLaudoId: payload.profissionalLaudoId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isMunicipal) {
      setValue('profissionalLaudoId', null);

      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);

      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setEstabelecimentosSelected([]);
        setSelectedHasLesao([]);
        setSelectedOrigem([]);
        setSelectedCarater([]);
        setSelectedEscamosa([]);
        setSelectedGlandular([]);
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsHistopatologia({
            estado: payload.estado,
            municipioId: payload.municipioId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isEstadual) {
      setValue('profissionalLaudoId', null);

      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);

      fieldsToClearWhenEstadual.forEach((field) => {
        setMunicipiosSelected([]);
        setEstabelecimentosSelected([]);
        setSelectedHasLesao([]);
        setSelectedOrigem([]);
        setSelectedCarater([]);
        setSelectedEscamosa([]);
        setSelectedGlandular([]);
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isSuperAdmin') {
      setValue('profissionalLaudoId', null);

      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setSelectedLaboratorio([]);
        setSelectedOptionsLaboratorio([]);

        setEstadosSelected([]);
        setMunicipiosSelected([]);
        setEstabelecimentosSelected([]);
        setSelectedHasLesao([]);
        setSelectedOrigem([]);
        setSelectedCarater([]);
        setSelectedEscamosa([]);
        setSelectedGlandular([]);

        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;
