import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsColposcopia,

  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterShow,

  setDataResultadoFinal,
  setDataResultadoIni,
  setSelectedResultado,
  setSelectedOptionsAvaliacao,
  setSelectedOptionsVisibilidade,
  setSelectedOptionsZt,
  setSelectedOptionsTiposAchados,
  setSelectedOptionsLugol,
  setSelectedOptionsSchiller,
  setSelectedOptionsBiopsia,
  setPayload,
  setDtColposcopiaIni,
  setDtColposcopiaFinal,
  setSelectedOptionsAlteracoes,
  setSelectedOptionsLaboratorio,
  setSelectedLaboratorio,
}) => {
  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const clearValues = async () => {
    setErrorsFields([]);
    setTableData([]);

    setDtColposcopiaIni(null);
    setDataResultadoFinal(null);
    setDtColposcopiaFinal(null);
    setDataResultadoIni(null);
    setSelectedResultado();
    setValue('profissionalResultadoId', null);
    setSelectedOptionsAvaliacao([]);
    setSelectedOptionsVisibilidade([]);
    setSelectedOptionsZt([]);
    setSelectedOptionsTiposAchados([]);
    setSelectedOptionsLugol([]);
    setSelectedOptionsSchiller([]);
    setSelectedOptionsBiopsia([]);
    setSelectedOptionsAlteracoes([]);

    const fieldsToClearWhenIsMedicoLaboratorio: any[] = [
      'data_ini_colposcopia',
      'data_fin_colposcopia',
      'macroscopico',
      'aval_geral',
      'visibilidade',
      'zt',
      'achados',
      'lugol',
      'schiller',
      'biopsia',
      'search',
      'estabelecimento_saude',
    ];
    const fieldsToClearWhenIsPadrao: any[] = [
      'data_ini_colposcopia',
      'data_fin_colposcopia',
      'macroscopico',
      'aval_geral',
      'visibilidade',
      'zt',
      'achados',
      'lugol',
      'schiller',
      'biopsia',
      'laboratorio_responsavel_laudo',
      'profissional_responsavel_laudo',
      'search',
    ];

    const fieldsToClearWhenSuperAdmin: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estado',
      'municipio',
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenIsMunicipal: any[] = [
      ...fieldsToClearWhenIsPadrao,

      'estabelecimento_saude',
    ];

    const fieldsToClearWhenEstadual = [
      'municipio',
      ...fieldsToClearWhenIsMunicipal,
    ];

    const permissaoId = getUserToken().permissao_atual.id;

    const isPadrao =
      permissaoId === 1 ||
      permissaoId === 9 ||
      permissaoId === 10 ||
      permissaoId === 7;

    const isMunicipal = permissaoId === 3;

    const isLaboratorio =
      getUserToken().permissao_atual.contexto === 'LABORATÓRIO';
    const isMedicoLaboratorio = isPadrao && isLaboratorio;

    if (isMunicipal) {
      setSelectedOptionsLaboratorio([]);
      setSelectedLaboratorio([]);

      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
        setSelectedOptionsLaboratorio([]);
      });

      setEstabelecimentosSelected([]);

      await setPayload().then((payload) => {
        try {
          loadReportsColposcopia({
            estado: payload.estado,
            municipioId: payload.municipioId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isPadrao && !isLaboratorio) {
      setSelectedOptionsLaboratorio([]);
      setSelectedLaboratorio([]);

      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsColposcopia({
            estado: payload.estado,
            municipioId: payload.municipioId,
            estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
            // laboratorioId: payload.laboratorioId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (isMedicoLaboratorio) {
      fieldsToClearWhenIsMedicoLaboratorio.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsColposcopia({
            estado: payload.estado,
            profissionalId: payload.profissionalId,
            laboratorioId: payload.laboratorioId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }
    if (permissao === 'isSuperAdmin') {
      setSelectedOptionsLaboratorio([]);
      setSelectedLaboratorio([]);

      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isEstadual' && !isLaboratorio) {
      setSelectedOptionsLaboratorio([]);
      setSelectedLaboratorio([]);

      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;
